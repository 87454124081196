import {assoc, dissoc} from 'ramda';
import {createSlice} from '@reduxjs/toolkit';

export const EMPTY_INPUT = 'empty';
export const INVALID_INPUT = 'invalid';

export const formErrorSlice = createSlice({
    name: 'formError',
    initialState: {
        formError: {},
    },
    reducers: {
        setFormError: (state, action) => {
            return {
                ...state,
                formError: action.payload,
            }
        },
        setNewValueInFormError: (state, action) => {
            return {
                ...state,
                formError: assoc(action.payload.property, action.payload.newValue, state.formError),
            }
        },
        removeValueFromFormError: (state, action) => {
            return {
                ...state,
                formError: dissoc(action.payload, state.formError),
            }
        },
    },
});

export const {actions, reducer} = formErrorSlice;

export const {
    setFormError,
    setNewValueInFormError,
    removeValueFromFormError,
} = actions;
