import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import RefreshButton, {STANDARD_BUTTON_TYPE} from '../layout/RefreshButton';
import RefreshButtonOnClickAction, {ONCLICK_DISPATCH_TYPE} from '../../model/RefreshButtonOnClickAction';
import SuccessSwitch from '../layout/variant/success-style-component/SuccessSwitch';
import {FormControlLabel, IconButton, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {clone, isEmpty, isNil} from 'ramda';
import {connect, useSelector} from 'react-redux';
import {equipmentModel, reset, setHasError, setIsLoading} from '../../store/reducer/equipment';
import {getEquipments} from '../../api-service/EquipmentApi';
import {grey, red} from '@material-ui/core/colors';
import {setNewValueInVehicle} from '../../store/reducer/vehicle';
import {splitToChunks} from '../../utils/Misc';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

const VehicleFormEquipment = ({
    vehicle,
    isLoading,
    hasError,
    loaded,
    dispatch,
}) => {
    const equipments = useSelector(equipmentModel);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        let isMat = [2,3,4].includes(parseInt(vehicle.typologie,10))
        if (!loaded) {
            if (
                isMat ||
                ((!isEmpty(vehicle.carBody) && !isNil(vehicle.carBody))
                || (!isEmpty(vehicle.vehicleType) && !isNil(vehicle.vehicleType)))
            ) {
                if (!hasError) {
                    getEquipments({
                        isPrivateVehicle: vehicle.vehicleType,
                        carBody: vehicle.carBody,
                        typologie : vehicle.typologie,
                    })
                        .catch(() => {
                            dispatch(setHasError(true));
                            enqueueSnackbar(
                                t('equipmentsNotLoadedError'),
                                {variant: 'error', autoHideDuration: 10000}
                            );
                            dispatch(setIsLoading(false));
                        })
                    ;
                }
            } else {
                enqueueSnackbar(
                    t('vehicleDoNotEmbedVehicleTypeOrCarBody'),
                    {variant: 'error', autoHideDuration: 10000}
                );
            }
        }
    }, [hasError, loaded]);

    /**
     * Check if the vehicle got the selected equipment in the list
     *
     * @returns {boolean}
     */
    const ownByVehicle = (selectedEquipment) => {
        let checked = false;

        vehicle.simplifiedEquipments.map(vehicleEquipment => {
            if (vehicleEquipment.code === selectedEquipment.code) {
                checked = true;
            }
        })

        return checked;
    }

    /**
     * If selectedEquipment isn't checked add it to vehicle.equipments
     * If selectedEquipment is checked remove it to vehicle.equipments
     */
    const handleEquipment = (selectedEquipment, checked) => {
        if (checked) {
            dispatch(setNewValueInVehicle(
                {
                    property: 'simplifiedEquipments',
                    newValue: vehicle.simplifiedEquipments.filter(
                        vehicleEquipment => vehicleEquipment.code !== selectedEquipment.code
                    ),
                }
            ))
        } else {
            let clonedVehicleEquipments = clone(vehicle.simplifiedEquipments);
            clonedVehicleEquipments.push(selectedEquipment);
            dispatch(setNewValueInVehicle({property: 'simplifiedEquipments', newValue: clonedVehicleEquipments}));
        }
    }

    /**
     * @See ownByVehicle, almost same except the last condition with defective
     *
     * @returns {boolean}
     */
    const isDefective = (selectedEquipment) => {
        let checked = false;
        vehicle.simplifiedEquipments.map(vehicleEquipment => {
            if (vehicleEquipment.code === selectedEquipment.code) {
                if (vehicleEquipment.defective === true) checked = true;
            }
        })

        return checked;
    }

    const handleDefectiveEquipment = (selectedEquipment) => {
        let clonedVehicleEquipments = clone(vehicle.simplifiedEquipments);

        clonedVehicleEquipments.map(vehicleEquipment => {
            if (vehicleEquipment.code === selectedEquipment.code) {
                vehicleEquipment.defective = vehicleEquipment.defective !== true;
            }
        })
        dispatch(setNewValueInVehicle({property: 'simplifiedEquipments', newValue: clonedVehicleEquipments}));
    }

    return (
        <>
            {isLoading
                ? <Grid container style={{whiteSpace: 'nowrap'}}>
                    {[...Array(144)].map((x, i) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            xl={2}
                            key={i}
                            style={{display: 'flex', alignItems: 'center', padding: '.2rem'}}
                        >
                            <Skeleton animation="wave" width={40} height={30}/>
                            <Skeleton animation="wave" width={20} height={30} style={{margin: '0 1rem 0 1rem'}}/>
                            <Skeleton
                                animation="wave"
                                height={30}
                                width={Math.floor(Math.random() * (Math.floor(150) - Math.ceil(50) + 1)) + Math.ceil(50)}
                            />
                        </Grid>
                    ))}
                </Grid>
                : isEmpty(equipments)
                    ? <Grid container style={{whiteSpace: 'nowrap'}}>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: '100vh'}}
                        >
                            <Grid item>
                                <i><Typography variant="h5">{t('noData')}</Typography></i>
                            </Grid>
                            <Grid item>
                                <RefreshButton
                                    isDisplayed={hasError}
                                    type={STANDARD_BUTTON_TYPE}
                                    onClickActions={[
                                        new RefreshButtonOnClickAction(
                                            {type: ONCLICK_DISPATCH_TYPE, action: reset()}
                                        ),
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    : <Grid container>
                        {splitToChunks(equipments, 4).map((chunks, key) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                                container
                                key={key}
                                style={{flexWrap: 'wrap'}}
                            >
                                {chunks.map((equipment, key) => (
                                    <Grid
                                        item
                                        key={equipment.code}
                                        style={{display: 'flex', alignItems: 'center', padding: '0 .2rem 0 .2rem', width: '100%'}}
                                    >
                                        <FormControlLabel
                                            control={
                                                <SuccessSwitch
                                                    id={key.toString()}
                                                    checked={ownByVehicle(equipment)}
                                                    onChange={e => handleEquipment(equipment, !e.target.checked)}
                                                    name="equipment"
                                                />
                                            }
                                            label={null}
                                        />
                                        {/* All of those css rules are needed to display a valid "fake" checkbox with an "D" in it */}
                                        <IconButton
                                            size="small"
                                            name="equipment"
                                            onClick={() => handleDefectiveEquipment(equipment)}
                                            disabled={!ownByVehicle(equipment)}
                                            style={{margin: '0 .6rem 0 -1rem'}}
                                        >
                                            <Grid
                                                style={{
                                                    border: 'solid 2px',
                                                    borderRadius: '3px',
                                                    transition: 'background-color .2s ease',
                                                    color: isDefective(equipment) ? 'red' : 'grey',
                                                    backgroundColor: isDefective(equipment)
                                                        ? red[100]
                                                        : !ownByVehicle(equipment)
                                                            ? grey[400]
                                                            : grey[300]
                                                    ,
                                                    height: '19px',
                                                    width: '18px',
                                                }}
                                            >
                                                <Grid style={{marginTop: '-.2rem'}}>
                                                    <b>D</b>
                                                </Grid>
                                            </Grid>
                                        </IconButton>
                                        <span style={{overflow: 'hidden'}}>{equipment.shortName}</span>
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
            }
        </>
    )
}

VehicleFormEquipment.propTypes = {
    vehicle: PropTypes.object.isRequired,
    isLoading : PropTypes.bool,
    hasError : PropTypes.bool,
    loaded : PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    isLoading: state.equipmentReducer.isLoading,
    hasError: state.equipmentReducer.hasError,
    loaded: state.equipmentReducer.loaded,
}))(VehicleFormEquipment);