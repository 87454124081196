import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import RefreshButton from './layout/RefreshButton';
import RefreshButtonOnClickAction, {ONCLICK_DISPATCH_TYPE} from '../model/RefreshButtonOnClickAction';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import displayStyle from '../assets/jss/displayStyle';
import textStyle from '../assets/jss/textStyle';
import {Autocomplete} from '@material-ui/lab';
import {CircularProgress, Typography} from '@material-ui/core';
import {HTTP_ERROR_TIMEOUT} from './error/ErrorPage';
import {connect, useSelector} from 'react-redux';
import {
    employeesModel,
    reset as resetEmployee,
    setEmployee,
    setIsLoading,
} from '../store/reducer/employee';
import {fetchUserToken} from '../utils/Authenticator';
import {getEmployees} from '../api-service/EmployeeApi';
import {makeStyles} from '@material-ui/styles';
import {reset as resetVehicleFolders} from '../store/reducer/vehicleFolder';
import {router} from '../router';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

const loginStyles = makeStyles (theme => ({
    ...textStyle,
    ...displayStyle,
    marginTop: {
        marginTop: theme.spacing(2),
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    form: {
        padding: theme.spacing(2),
    },
    formControl: {
        width: '100%',
    },
    paper: {
        width: '50%',
        margin: theme.spacing(1),
        transition: 'margin-right 1s ease, margin-left 1s ease',
    },
    login: {
        marginLeft: '0%',
    },
    employeeSelector: {
        marginRight: '-150%',
        marginLeft: '100%',
    },
    loginSuccess: {
        marginLeft: '-150%',
    },
    employeeSelectorInLogin: {
        marginLeft: '100%',
        marginRight: '0',
    },
    fullRotate: {
        transform: 'rotate(360deg)',
    },
}));

const Login = ({
    dispatch,
    employeesLoaded,
    isLoadingEmployees,
    hasErrorEmployees,
    user,
}) => {
    const {register, handleSubmit, errors} = useForm();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLogin, setIsLogin] = useState(!!user);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    // Employees request on error is handled here instead of in the store cause this error can appear only in this component.
    const employees = useSelector(employeesModel);
    const classes = loginStyles();
    const history = useHistory();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(resetVehicleFolders())
    }, [true])

    /**
     * This useEffect must observe :
     *  - isLogin : if the login is a success
     *  - employeeLoaded : if employees already loaded or not.
     *  - isEmployeesRequestOnError : if a request attempt has been a fail or not.
     *  For this last, a reload button set it to false when it is true (see down below).
     *
     *  If the request has a fail, the request make one more attempt.
     */
    useEffect(() => {
        if (isLogin && !employeesLoaded && !hasErrorEmployees) {
            getEmployees().catch(() => {
                dispatch(setIsLoading(false));
                enqueueSnackbar(
                    t('employeesNotLoadedError'),
                    {variant: 'error'}
                );
            })
        }
    }, [isLogin, employeesLoaded, hasErrorEmployees])

    const onLoginSubmit = data => {
        setLoading(true);
        fetchUserToken(data['username'], data['password'], dispatch)
            .then(() => {
                setLoading(false);
                setIsLogin(true);
            })
            .catch(error => {
                if (error.message === HTTP_ERROR_TIMEOUT) {
                    enqueueSnackbar(
                        t('timeoutExceeded'),
                        {variant: 'error'}
                    );
                    setLoading(false);
                    setError(true);
                } else {
                    setLoading(false);
                    setError(true);
                }
            })
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '100vh', overflow: 'hidden'}}
        >
            <Typography variant="h1">{t('fleetStatement')}</Typography>
            <Grid item className={classes.formContainer}>
                <Paper
                    className={clsx(classes.paper, isLogin ? classes.loginSuccess : classes.login)}
                    elevation={2}
                >
                    {loading ? <LinearProgress/> : <div style={{height: '4px'}}/>}
                    <form onSubmit={handleSubmit(onLoginSubmit)} className={classes.form}>
                        <FormControl className={classes.formControl}>
                            {error
                                ? <FormHelperText error className={classes.marginTop}>{t('invalidId')}</FormHelperText>
                                : null
                            }
                            <TextField
                                id="username"
                                name="username"
                                label={t('username')}
                                variant="outlined"
                                className={classes.marginTop}
                                error={(Object.prototype.hasOwnProperty.call(errors, 'username') || error)}
                                inputProps={{
                                    ref: register({
                                        required: 'required',
                                    }),
                                }}
                            />
                            <TextField
                                id="password"
                                name="password"
                                label={t('password')}
                                type="password"
                                variant="outlined"
                                className={classes.marginTop}
                                error={(Object.prototype.hasOwnProperty.call(errors, 'password') || error)}
                                inputProps={{
                                    ref: register({
                                        required: 'required',
                                    }),
                                }}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.marginTop}
                                type="submit"
                                disabled={loading}
                            >
                                {t('login')}
                            </Button>
                        </FormControl>
                    </form>
                </Paper>
                <Paper
                    className={clsx(classes.paper, isLogin ? classes.employeeSelectorInLogin : classes.employeeSelector)}
                    elevation={2}
                    style={{padding: '1rem'}}
                >
                    <form style={{height: '100%'}}>
                        <Grid container style={{height: '100%'}}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={11} container direction="row">
                                    <PersonIcon/>
                                    <Typography variant="h6">{t('whoAreYou')}</Typography>
                                </Grid>
                                <Grid item>
                                    <RefreshButton
                                        size="small"
                                        isDisplayed={hasErrorEmployees}
                                        disabled={employeesLoaded}
                                        onClickActions={[
                                            new RefreshButtonOnClickAction(
                                                {type: ONCLICK_DISPATCH_TYPE, action: resetEmployee()}
                                            ),
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={selectedEmployee}
                                    onChange={(e, newValue) => {
                                        setSelectedEmployee(newValue);
                                    }}
                                    getOptionLabel={(option) => option.lastName + ' ' + option.firstName}
                                    id="employeeId"
                                    options={employees}
                                    loading={isLoadingEmployees}
                                    fullWidth
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label={t('employees')}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isLoadingEmployees ? <CircularProgress size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item container spacing={1} justifyContent="space-between" style={{alignSelf: 'flex-end'}}>
                                <Grid item xs={3}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        onClick={() => setIsLogin(false)}
                                        startIcon={<ArrowBackIcon />}
                                    >
                                        {t('goBack')}
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        onClick={() => {
                                            dispatch(setEmployee(selectedEmployee));
                                            history.push(router.vehicleList.path);
                                        }}
                                        disabled={!selectedEmployee}
                                    >
                                        {t('select')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

Login.propTypes = {
    employeesLoaded: PropTypes.bool,
    isLoadingEmployees: PropTypes.bool,
    hasErrorEmployees: PropTypes.bool,
    user: PropTypes.object,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    employeesLoaded: state.employeeReducer.loaded,
    isLoadingEmployees: state.employeeReducer.isLoading,
    hasErrorEmployees: state.employeeReducer.hasError,
    user: state.authenticationReducer.user,
}))(Login);