import CarBodyDescription from './VehiclePartlyCarBody/CarBodyDescription';
import CustomCard from '../layout/CustomCard';
import DescriptionIcon from '@material-ui/icons/Description';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FormFieldRender from '../layout/FormFieldRender';
import GeneralRatingBadge from './VehiclePartlyCarBody/GeneralRatingBadge';
import Grid from '@material-ui/core/Grid';
import ListIcon from '@material-ui/icons/List';
import PartlyCarBody from './VehiclePartlyCarBody/PartlyCarBody';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import RepairAmount from './VehiclePartlyCarBody/RepairAmount';
import {LinearProgress} from '@material-ui/core';
import {applyRepairCostDeprecationRule} from '../../utils/Misc';
import {clone} from 'ramda';
import {connect, useSelector} from 'react-redux';
import {
    leftTallFields,
    radioAndCheckboxFields,
    rightSmallFields,
} from '../../utils/fields-provider/VehiclePartlyCarBodyFieldsProvider';
import {makeStyles} from '@material-ui/styles';
import {repairCostsModel} from '../../store/reducer/vehicleAnnexeData';
import {setNewValueInVehicle} from '../../store/reducer/vehicle';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

const VehicleFormCarBody = ({
    vehicle,
    innerJoinPartlyCarBodyData,
    isLoadingVehicleAnnexeData,
    vehicleAnnexeLoaded,
    dispatch,
}) => {
    const classes = useStyles();
    const repairCostData = useSelector(repairCostsModel);
    const {t} = useTranslation();

    const defaultPrivateVehicleSegment = 'BM';
    const defaultProfessionalVehicleSegment = 'FP';

    useEffect(() => {
        if (vehicleAnnexeLoaded) {
            let clonedVehicle = clone(vehicle);
            let totalAmount = 0;
            let filteredRepairCostData = [];

            if (vehicle.segment) {
                filteredRepairCostData = repairCostData.filter(repairCostDatum => repairCostDatum.segment === vehicle.segment);
            } else if (vehicle.vehicleType === 'VP') {
                filteredRepairCostData = repairCostData.filter(repairCostDatum => repairCostDatum.segment === defaultPrivateVehicleSegment);
            } else if (vehicle.vehicleType === 'VU') {
                filteredRepairCostData = repairCostData.filter(repairCostDatum => repairCostDatum.segment === defaultProfessionalVehicleSegment);
            }

            filteredRepairCostData.forEach(repairCostDatum => {
                clonedVehicle.simplifiedCarBodyParts.forEach(carBodyPart => {
                    if (repairCostDatum.partCode === carBodyPart.partCode
                        && repairCostDatum.partState == carBodyPart.partState
                    ) {
                        totalAmount += repairCostDatum.repairAmount;
                    }
                })
            })

            totalAmount = applyRepairCostDeprecationRule(totalAmount, vehicle.registeredAt);

            if (totalAmount < 0) totalAmount = 0;

            dispatch(setNewValueInVehicle({property: 'internalRepairAmount', newValue: totalAmount}));
        }
    }, [vehicle.simplifiedCarBodyParts, vehicleAnnexeLoaded]);

    return (
        <form className={classes.root}>
            <Grid container>
                <Grid container item xs={12}>
                    <CustomCard label={t('generalInfo')} icon={<DescriptionIcon/>}>
                        <Grid container item xs={12}>
                            <Grid item xs={10} style={{padding: '0 .2rem 0 .2rem'}}>
                                {leftTallFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                        disabled={field.disabled}
                                    />
                                )}
                            </Grid>
                            <Grid container item xs={2} style={{padding: '0 .2rem 0 .2rem'}}>
                                {rightSmallFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                        disabled={field.disabled}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} container style={{padding: '0 .2rem 0 .2rem'}} justifyContent="space-between">
                                {radioAndCheckboxFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} xl={5} style={{display: 'flex', alignItems: 'flex-start'}}>
                    <CustomCard
                        label={t('partlyCarBody')}
                        icon={<DriveEtaIcon/>}
                        headerElement={
                            <GeneralRatingBadge
                                generalState={vehicle.generalState}
                                isLoading={isLoadingVehicleAnnexeData}
                            />
                        }
                    >
                        <Grid container justifyContent="center">
                            <PartlyCarBody vehicle={vehicle}/>
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} xl={7} style={{display: 'flex'}}>
                    <CustomCard
                        label={t('partlyCarBodyDescription')}
                        icon={<ListIcon/>}
                        headerElement={
                            <RepairAmount
                                repairAmount={vehicle.internalRepairAmount}
                                isLoading={isLoadingVehicleAnnexeData}
                            />
                        }
                    >
                        <Grid item xs={12}>
                            {vehicle.simplifiedCarBodyParts.length === 0
                                ? <i>{t('noData')}</i>
                                : isLoadingVehicleAnnexeData
                                    ? <>
                                        <span>{t('loading')}</span>
                                        <LinearProgress/>
                                    </>
                                    : <CarBodyDescription
                                        vehicle={vehicle}
                                        innerJoinPartlyCarBodyData={innerJoinPartlyCarBodyData}
                                    />
                            }
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
        </form>
    )
}

VehicleFormCarBody.propTypes = {
    vehicle: PropTypes.object.isRequired,
    innerJoinPartlyCarBodyData: PropTypes.array.isRequired,
    isLoadingVehicleAnnexeData: PropTypes.bool,
    vehicleAnnexeLoaded: PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    isLoadingVehicleAnnexeData: state.vehicleAnnexeDataReducer.isLoading,
    vehicleAnnexeLoaded: state.vehicleAnnexeDataReducer.loaded,
}))(VehicleFormCarBody);