import {createSlice} from '@reduxjs/toolkit';

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        openModalId: '',
    },
    reducers: {
        setOpenModalId: (state, action) => {
            return {
                ...state,
                openModalId: action.payload,
            }
        },
    },
});

export const {actions, reducer} = modalSlice;

export const {setOpenModalId} = actions;
