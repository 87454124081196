import DataTableFilter from './DataTableFilter';
import DataTableHeader from './DataTableHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Box, LinearProgress} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {clone} from 'ramda';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    headerContainer: {
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const DataTable = ({
    filters,
    headers,
    items,
    defaultOrderBy = '',
    defaultOrderDesc = false,
    loading,
    handleClickLine = false,
    search,
}) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [order, setOrder] = useState(!defaultOrderDesc ? 'asc' : 'desc');
    const {t} = useTranslation();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataFlag, setFilteredDataFlag] = useState(false);

    /**
     * Each time the search array changes, filteredData is "reset" to initial state.
     */
    useEffect(() => {
        setFilteredData(items);
        setFilteredDataFlag(true)
    }, [search])

    /**
     * When the previous useEffect() is done, the following is trigger.
     * It filters the items array by search values.
     */
    useEffect(() => {
        if (filteredDataFlag) {
            let clonedFilteredData = clone(filteredData);
            for (let field in search) {
                if (Object.prototype.hasOwnProperty.call(search, field)) {
                    clonedFilteredData = clonedFilteredData.filter(item => {
                        if (search[field] === '') {
                            return true;
                        }

                        return item[field] === search[field];
                    });
                }
            }

            setFilteredData(clonedFilteredData);
            setFilteredDataFlag(false);
        }
    }, [filteredDataFlag])

    useEffect(() => {
        if (filteredData.length === 0) {
            setFilteredData(items)
        }
    }, [items])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <div className={classes.headerContainer}>
                {loading
                    ? <Grid container>
                        {filters.map(filter => (
                            <Grid item xs={1} key={filter.id} style={{margin: '.5rem .5rem .3rem 0'}}>
                                <Skeleton
                                    height={40}
                                    style={{transform: 'none'}}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    : filters.map(filter => (
                        <DataTableFilter
                            key={filter.id}
                            id={filter.id}
                            label={filter.label}
                            choices={filter.choices}
                        />
                    ))
                }
            </div>
            <Paper elevation={3} style={{borderRadius: '5px'}}>
                <TableContainer style={{borderRadius: '5px'}}>
                    <Table stickyHeader size="small">
                        <DataTableHeader
                            headers={headers}
                            defaultOrderBy={defaultOrderBy}
                            defaultOrderDesc={defaultOrderDesc}
                            onRequestSort={handleRequestSort}
                            orderBy={orderBy}
                            order={order}
                        />
                        <TableBody>
                            {loading
                                ? <TableRow>
                                    <TableCell colSpan={headers.length}>
                                        <span>{t('loading')}</span>
                                        <LinearProgress/>
                                    </TableCell>
                                </TableRow>
                                : filteredData.length === 0
                                    ? <TableRow>
                                        <TableCell
                                            colSpan={headers.length}
                                            align='center'
                                        >
                                            <Box fontStyle="italic">
                                                {t('noData')}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    : stableSort(filteredData, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((filteredDatum, index) => (
                                            <TableRow key={index} {...(handleClickLine ? {
                                                onClick: () => handleClickLine(filteredDatum),
                                                hover: true,
                                            } : null)}>
                                                {headers.map(header => (
                                                    <TableCell
                                                        style={{width: header.width, display: header.display}}
                                                        key={header.id}
                                                        align={header.align}
                                                    >
                                                        <>
                                                            {header.component
                                                                ? header.component(filteredDatum)
                                                                : Object.prototype.hasOwnProperty.call(header, 'format')
                                                                    ? header.format(filteredDatum[header.id])
                                                                    : filteredDatum[header.id]
                                                            }
                                                        </>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 250, 500]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('linePerPage')}
                />
            </Paper>
        </>
    );
}

DataTable.propTypes = {
    filters: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    defaultOrderBy: PropTypes.string,
    defaultOrderDesc: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    handleClickLine: PropTypes.oneOfType([
        PropTypes.func, PropTypes.bool,
    ]),
    search: PropTypes.object,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    search: state.datatableReducer.search,
}))(DataTable);