import {BaseModel} from 'sjs-base-model';

export default class Employee extends BaseModel {
    employeeId = '';
    firstName = '';
    lastName = '';
    storageParc = ''
    storageParcCode = ''

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
        this.employeeId = data['empID']
        this.firstName = data['prenom']
        this.lastName = data['nom']
        this.storageParc = data['parcStockageVille']
        this.storageParcCode = data['parcStockage']
    }
}