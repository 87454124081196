import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import {CircularProgress, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const RepairAmount = ({
    repairAmount,
    isLoading = false,
}) => {
    const {t} = useTranslation();

    return (
        <Grid container justifyContent="flex-end" alignItems="baseline" spacing={2}>
            <Grid item>
                <Typography variant="body1">
                    <strong>{t('repairAmount')}</strong> :
                </Typography>
            </Grid>
            {/* Setting this padding cause if not there is a little Y position changes of the up above sentence due to the CircleProgress. */}
            <Grid item style={isLoading ? {paddingTop: '3px'} : null}>
                {isLoading
                    ? <CircularProgress size={20}/>
                    : <Typography>{repairAmount} €</Typography>
                }
            </Grid>
        </Grid>
    );
}

RepairAmount.propTypes = {
    repairAmount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
}

export default RepairAmount;