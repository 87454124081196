import DataTable from './layout/DataTable';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import VehicleListHeader from './vehicle-list/VehicleListHeader';
import {connect, useSelector} from 'react-redux';
import {
    fleetStatementStatus,
    photoProductStatus,
    saleDate,
    technicalInspectionStatus,
    todoStatus,
    vehicleFoldersModel,
} from '../store/reducer/vehicleFolder';
import {getVehicleFolders} from '../api-service/VehicleFolderApi';
import {iconProvider, typologyProvider} from '../utils/Misc';
import {reset as resetAldStore} from '../store/reducer/ald';
import {reset as resetEquipmentStore} from '../store/reducer/equipment';
import {router} from '../router';
import {setFormError} from '../store/reducer/formError';
import {setHasError as setHasErrorControlledList} from '../store/reducer/controlledList';
import {setHasError as setHasErrorVehicleAnnexeData} from '../store/reducer/vehicleAnnexeData';
import {setHeader} from '../store/reducer/app';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const VehicleList = ({
    connectionLost,
    employee,
    vehicleFoldersLoaded,
    dispatch,
}) => {
    const history = useHistory();
    const vehicleFolders = useSelector(vehicleFoldersModel);
    const fleetStatementStatusList = useSelector(fleetStatementStatus);
    const technicalInspectionStatusList = useSelector(technicalInspectionStatus);
    const photoProductStatusList = useSelector(photoProductStatus);
    const saleDates = useSelector(saleDate);
    const todoStatusList = useSelector(todoStatus);
    const {t} = useTranslation();

    const handleClickRow = (vehicleFolder) => {
        if (!connectionLost) {
            history.push(router.vehicleForm.formattedPath + vehicleFolder.docEntry);
        }
    }

    useEffect(() => {
        dispatch(setHeader(<VehicleListHeader vehicleFoldersLoaded={vehicleFoldersLoaded}/>))

        return () => {
            dispatch(setHeader(null));
        }
    }, [vehicleFoldersLoaded])

    useEffect(() => {
        if (!vehicleFoldersLoaded) {
            getVehicleFolders({storageParcCode: employee.storageParcCode}).catch(error => {
                history.replace(router.errorPage.formattedPath + error.message);
            });
        }
    }, [vehicleFoldersLoaded]);

    /**
     * Reset some stores
     */
    useEffect(() => {
        dispatch(resetAldStore())
        dispatch(resetEquipmentStore());
    }, [true])

    /**
     * Reset some errors store states
     */
    useEffect(() => {
        dispatch(setFormError({}))

        // Reset those allow to retried the requests when you go from a vehicle to another by selecting it on the list
        dispatch(setHasErrorVehicleAnnexeData(false));
        dispatch(setHasErrorControlledList(false));
    }, [true]);

    // TODO : put filter in store
    const datatablesFilters = [
        {
            id: 'fleetStatementStatus',
            label: t('status'),
            choices:
                fleetStatementStatusList.map((value, index) =>
                    <MenuItem value={value} key={index}>
                        {value === 'DONE'
                            ? t('doneFleetStatement')
                            : t('todoFleetStatement')
                        }
                    </MenuItem>
                )
            ,
        },
        {
            id: 'saleDate',
            label: t('sale'),
            choices:
                saleDates.map((value, index) =>
                    <MenuItem value={value} key={index}>{value}</MenuItem>
                )
            ,
        },
        {
            id: 'todoStatus',
            label: t('todo'),
            choices:
                todoStatusList.map((value, index) => {
                    if (value && value !== 'null') {
                        return <MenuItem value={value} key={index}>{value}</MenuItem>
                    }
                })
            ,
        },
        {
            id: 'isAld',
            label: t('ald'),
            choices:
                [true, false].map((value, index) =>
                    <MenuItem value={value} key={index}>{!value ? t('no') : t('yes')}</MenuItem>
                )
            ,
        },
        {
            id: 'technicalInspectionStatus',
            label: t('shortenTechnicalInspection'),
            choices:
            // Supposed to get these values : DOABLE, DONE, NO, which are, in lower case, translated
                technicalInspectionStatusList.map((value, index) => {
                    if (value && value !== 'null') {
                        return <MenuItem value={value} key={index}>{t(value.toString().toLowerCase())}</MenuItem>
                    }
                })
            ,
        },
        {
            id: 'photoProductStatus',
            label: t('studio'),
            choices:
            // Supposed to get these values : DOABLE, DONE, which are, in lower case, translated
                photoProductStatusList.map((value, index) =>
                    <MenuItem value={value} key={index}>{t(value.toString().toLowerCase())}</MenuItem>
                )
            ,
        },
    ];

    const datatablesHeaders = [
        {id: 'registrationNumber', label: t('shortenRegistrationNumber'), width: '6rem'},
        {id: 'vehicleType', label: t('vehicleType')},
        {id: 'commercialType', label: t('commercialType')},
        {
            id: 'registeredAtMoment',
            label: t('registeredAt'),
            format: (value) => value ? value.format('DD/MM/YYYY') : '',
        },
        {id: 'color', label: t('color')},
        {id: 'vendor', label: t('vendor')},
        {
            id: 'deliveredAtMoment',
            label: t('deliveredAt'),
            format: (value) => value ? value.format('DD/MM/YYYY') : '',
        },
        {id: 'transporter', label: t('transporter')},
        {id: 'saleDate', label: t('sale')},
        {
            id: 'orderNumber',
            label: t('shortenOrderNumber'),
            component: (value) => renderOrderNumber(value),
        },
        {
            id: 'technicalInspectionStatusIcon',
            label: t('shortenTechnicalInspection'),
            component: (value) => iconProvider(value.technicalInspectionStatusIcon),
        },
        {
            id: 'photoProductStatusIcon',
            label: t('studio'),
            component: (value) => iconProvider(value.photoProductStatusIcon),
        },
        {
            id: 'photoMiscStatusIcon',
            label: t('misc'),
            component: (value) => iconProvider(value.photoMiscStatusIcon),
        },
        {
            id: 'controlledAtMoment',
            label: t('controlledAt'),
            format: (value) => value ? value.format('DD/MM/YYYY') : '',
        },
        {
            id: 'typology',
            label: t('typology'),
            format: (value) => typologyProvider(value),
        },
        {id: 'todoStatus', display: 'none'},
        {id: 'fleetStatementStatus', display: 'none'},
        {id: 'technicalInspectionStatus', display: 'none'},
        {id: 'photoProductStatus', display: 'none'},
        {id: 'isAld', display: 'none'},
    ];

    const renderOrderNumber = (value) => {
        return value.orderNumber + value.complementOrderNumber;
    }

    return (
        <>
            <Grid container justifyContent="center">
                <Grid xs={12} item style={{padding: '0 1rem 0 1rem'}}>
                    <DataTable
                        filters={datatablesFilters}
                        headers={datatablesHeaders}
                        items={vehicleFolders}
                        loading={!vehicleFoldersLoaded}
                        handleClickLine={handleClickRow}
                    />
                </Grid>
            </Grid>
        </>
    );
}

VehicleList.propTypes = {
    connectionLost: PropTypes.bool,
    employee: PropTypes.object,
    vehicleFoldersLoaded: PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    connectionLost: state.appReducer.connectionLost,
    employee: state.employeeReducer.employee,
    vehicleFoldersLoaded: state.vehicleFolderReducer.vehicleFoldersLoaded,
}))(VehicleList);
