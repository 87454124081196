import i18n from 'i18next';
import {CHECKBOX_TYPE, DATE_TYPE, NUMBER_TYPE, RADIO_TYPE} from '../../components/layout/FormFieldRender';

export const keyFields = () => {
    return [
        {
            label: i18n.t('keys'),
            field: 'hasKey',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('yes'), value: 'Y'},
                {label: i18n.t('no'), value: 'N'},
            ],
        },
        {
            label: i18n.t('duplicateKeys'),
            field: 'keyType',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('yes'), value: 'Y'},
                {label: i18n.t('no'), value: 'N'},
                {label: i18n.t('triple'), value: 'T'},
            ],
        },
    ];
}

export const generalFields = () => {
    return [
        {
            label: i18n.t('maintenanceBook'),
            field: 'maintenanceBook',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('yes'), value: 'Y'},
                {label: i18n.t('no'), value: 'N'},
                {label: 'PAJ', value: 'PAJ'},
            ],
        },
        {
            label: i18n.t('deliveredAt') + ' ' + i18n.t('maintenanceBook'),
            field: 'maintenanceBookDeliveredAt',
            type: DATE_TYPE,
        },
        {
            label: i18n.t('userManual'),
            field: 'userManual',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('yes'), value: 'Y'},
                {label: i18n.t('no'), value: 'N'},
            ],
        },
    ]
}

export const problemFields = () => {
    return [
        {
            label: i18n.t('battery'),
            field: 'hasBatteryProblem',
            type: CHECKBOX_TYPE,
        },
        {
            label: i18n.t('starting'),
            field: 'hasStartProblem',
            type: CHECKBOX_TYPE,
        },
        {
            label: i18n.t('oil'),
            field: 'hasOilProblem',
            type: CHECKBOX_TYPE,
        },
        {
            label: i18n.t('coolingLiquid'),
            field: 'hasCoolingLiquidProblem',
            type: CHECKBOX_TYPE,
        },
    ]
}

export const warrantyFields = () => {
    return [
        {
            label: i18n.t('warranty'),
            field: 'manufacturerWarranty',
            type: CHECKBOX_TYPE,
        },
        {
            label: i18n.t('expiry'),
            field: 'manufacturerWarrantyExpiresAt',
            type: DATE_TYPE,
            split: 1,
        },
        {
            label: i18n.t('kilometersOdometer'),
            field: 'kilometersOdometer',
            type: NUMBER_TYPE,
            split: 2,
        },
    ]
}

export const overhaulFields = () => {
    return [
        {
            label: i18n.t('overhaul'),
            field: 'overhaul',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('todo'), value: '0'},
                {label: i18n.t('done'), value: '1'},
                {label: i18n.t('notApplicable'), value: '2'},
            ],
        },
    ]
}
