import {BaseModel} from 'sjs-base-model';

export default class Ald extends BaseModel {
    code = '';
    name = '';
    docEntryVehicle = 0;
    rightFrontDoor = '';
    leftFrontDoor = '';
    rightBackDoor = '';
    leftBackDoor = '';
    rightFrontFender = '';
    leftFrontFender = '';
    rightBackFender = '';
    leftBackFender = '';
    rightSill = '';
    leftSill = '';
    frontBumper = '';
    backBumper = '';
    carHood = '';
    carRoof = '';
    carTrunk = '';
    leftMirror = '';
    rightMirror = '';
    utilityVehicleRightBackDoor = '';
    utilityVehicleLeftBackDoor = '';
    utilityVehicleSlidingDoor = '';
    dashboard = '';
    aldSerialNumber = '';
    hasRadio = false;
    hasPhone = false;
    hasGpsAndHisDisk = false;
    miscEquipment = '';
    miscInterior = '';
    rightFrontSeat = '';
    leftFrontSeat = '';
    backSeat = '';
    frontCarpet = '';
    backCarpet = '';
    comment = '';
    generalState = '';
    spareWheel = '';
    engine = '';
    gearbox = '';
    clutch = '';

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
    }
}