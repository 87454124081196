import Vehicle from '../../model/Vehicle';
import {assoc} from 'ramda';
import {createSelector, createSlice} from '@reduxjs/toolkit';

const initialState = {
    vehicle: {},
    vehicleLoaded: false,
    isLoading: false,
    isPersisting: false,
    apiResult: {},
};

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState: initialState,
    reducers: {
        setVehicle: (state, action) => {
            return {
                ...state,
                vehicle: action.payload,
                vehicleLoaded: true,
                isLoading: false,
            }
        },
        setNewValueInVehicle: (state, action) => {
            return {
                ...state,
                vehicle: assoc(action.payload.property, action.payload.newValue, state.vehicle),
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            }
        },
        setIsPersisting: (state, action) => {
            return {
                ...state,
                isPersisting: action.payload,
            }
        },
        setApiResult: (state, action) => {
            return {
                ...state,
                apiResult: action.payload,
            }
        },
        reset(state) {
            Object.assign(state, initialState)
        },
    },
});

export const vehicleModel = createSelector(
    state => state.vehicleReducer.vehicle,
    vehicle => new Vehicle(vehicle)
);

export const {actions, reducer} = vehicleSlice;

export const {
    setVehicle,
    setNewValueInVehicle,
    setIsLoading,
    setIsPersisting,
    setApiResult,
    reset,
} = actions;
