import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {setNewValueInSearch} from '../../store/reducer/datatable';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
        marginRight: theme.spacing(2),
    },
}));

const DataTableFilter = ({
    id,
    label = '',
    choices,
    search,
    dispatch,
}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <FormControl
            variant="outlined"
            size="small"
            margin="dense"
            className={classes.formControl}
        >
            <InputLabel id={label}>{label}</InputLabel>
            <Select
                labelId={label}
                label={label}
                onChange={e => {
                    dispatch(setNewValueInSearch({property: id, newValue: e.target.value}))
                }}
                value={typeof search[id] !== 'undefined' ? search[id] : ''}
            >
                <MenuItem value="">
                    {t('empty')}
                </MenuItem>
                {choices}
            </Select>
        </FormControl>
    )
}

DataTableFilter.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    choices: PropTypes.array.isRequired,
    search: PropTypes.object,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    search: state.datatableReducer.search,
}))(DataTableFilter);