import {assoc} from 'ramda';
import {createSlice} from '@reduxjs/toolkit';

export const datatableSlice = createSlice({
    name: 'datatable',
    initialState: {
        search: {},
    },
    reducers: {
        setSearch: (state, action) => {
            return {
                ...state,
                search: action.payload,
            }
        },
        setNewValueInSearch: (state, action) => {
            return {
                ...state,
                search: assoc(action.payload.property, action.payload.newValue, state.search),
            }
        },
    },
});

export const {actions, reducer} = datatableSlice;

export const {setSearch, setNewValueInSearch} = actions;