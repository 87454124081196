import moment from 'moment';
import {BaseModel} from 'sjs-base-model';
import {stringToMoment} from '../utils/data-transformer/DateTransformer';

export default class VehicleFolder extends BaseModel {
    docEntry = '';
    docNum = '';
    brand = '';
    registrationNumber = '';
    vehicleType = '';
    commercialType = '';
    color = '';
    vendor = '';
    transporter = '';
    orderNumber = '';
    complementOrderNumber = '';
    isAld = false;
    todoStatus = '';
    technicalInspectionStatusIcon = '';
    technicalInspectionStatus = '';
    photoProductStatusIcon = '';
    photoProductStatus = '';
    photoMiscStatusIcon = '';
    fleetStatementStatus = '';
    saleDate = '';
    controlledAt = '';
    deliveredAt = '';
    serialNumber = '';
    typology='';

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
        this.docEntry = data['docEntryVH'] ?? '';
        this.docNum = data['dossierRefInt'] ?? '';
        this.brand = data['marque'] ?? '';
        this.registrationNumber = data['immatriculation'] ?? '';
        this.vehicleType = data['genre'] ?? '';
        this.commercialType = data['typeComm'] ?? '';
        this.serialNumber = data['numeroDeSerie'] ?? 'hh';
        this.color = data['couleur'] ?? '';
        this.vendor = data['cardNameVendeur'] ?? '';
        this.transporter = data['transporteur'] ?? '';
        this.orderNumber = data['numeroOrdre'] ?? '';
        this.complementOrderNumber = data['numeroOrdreComplement'] ?? '';
        this.isAld = data['statutReleveSpecial'] !== 'NO';
        this.todoStatus = data['statutAFaire'] ?? '';
        this.technicalInspectionStatusIcon = data['statutControleTechniqueIcon'] ?? '';
        this.technicalInspectionStatus = data['statutControleTechnique'] ?? '';
        this.photoProductStatusIcon = data['statutPhotoProductIcon'] ?? '';
        this.photoProductStatus = data['statutPhotoProduct'] ?? '';
        this.photoMiscStatusIcon = data['statutPhotoMiscIcon'] ?? '';
        this.saleDate = data['dateVenteFR'] ?? '';
        this.controlledAt = moment(data['dateReleveParc']).isValid() ? moment(data['dateReleveParc']).format('DD/MM/YYYY') : '';
        this.deliveredAt = moment(data['dateLivraison']).isValid() ? moment(data['dateLivraison']).format('DD/MM/YYYY') : '';
        this.fleetStatementStatus = data['statutReleveParc'] ?? '';
        this.typology = data['typologie'] ?? '';
        // Need moment obj to sort data in DataTables
        this.deliveredAtMoment = stringToMoment(data['dateLivraison'], 'YYYY-MM-DD');
        this.controlledAtMoment = stringToMoment(data['dateReleveParc'], 'YYYY-MM-DD');
        this.registeredAtMoment = stringToMoment(data['date1MEC'], 'YYYY-MM-DD');
    }
}