import VehicleFolder from '../../model/VehicleFolder';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import {groupBy} from 'ramda';

const initialState = {
    vehicleFolders: [],
    vehicleFoldersLoaded: false,
    isLoading: false,
}

export const vehicleFolderSlice = createSlice({
    name: 'vehicleFolder',
    initialState: initialState,
    reducers: {
        setVehicleFolders: (state, action) => {
            return {
                ...state,
                vehicleFolders: action.payload,
                vehicleFoldersLoaded: true,
                isLoading: false,
            }
        },
        setVehicleFoldersLoaded: (state, action) => {
            return {
                ...state,
                vehicleFoldersLoaded: action.payload,
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            }
        },
        reset(state) {
            Object.assign(state, initialState)
        },
    },
});

export const vehicleFoldersModel = createSelector(
    state => state.vehicleFolderReducer.vehicleFolders,
    vehicleFolders => vehicleFolders.map(vehicleFolder => new VehicleFolder(vehicleFolder))
);

export const fleetStatementStatus = createSelector(
    state => state.vehicleFolderReducer.vehicleFolders,
    vehicleFolders => Object.keys(groupBy((obj) => obj['statutReleveParc'], vehicleFolders))
);

export const saleDate = createSelector(
    state => state.vehicleFolderReducer.vehicleFolders,
    vehicleFolders => Object.keys(groupBy((obj) => obj['dateVenteFR'], vehicleFolders))
);

export const todoStatus = createSelector(
    state => state.vehicleFolderReducer.vehicleFolders,
    vehicleFolders => Object.keys(groupBy((obj) => obj['statutAFaire'], vehicleFolders))
);

export const technicalInspectionStatus = createSelector(
    state => state.vehicleFolderReducer.vehicleFolders,
    vehicleFolders => Object.keys(groupBy((obj) => obj['statutControleTechnique'], vehicleFolders))
);

export const photoProductStatus = createSelector(
    state => state.vehicleFolderReducer.vehicleFolders,
    vehicleFolders => Object.keys(groupBy((obj) => obj['statutPhotoProduct'], vehicleFolders))
);

export const {actions, reducer} = vehicleFolderSlice;

export const {
    setVehicleFolders,
    setVehicleFoldersLoaded,
    setIsLoading,
    reset,
} = actions;
