import Equipment from '../../model/Equipment';
import {createSelector, createSlice} from '@reduxjs/toolkit';

const initialState = {
    equipments: [],
    isLoading: false,
    hasError: false,
    loaded: false,
};

export const equipmentSlice = createSlice({
    name: 'equipment',
    initialState: initialState,
    reducers: {
        setEquipments: (state, action) => {
            return {
                ...state,
                equipments: action.payload,
                loaded: true,
                isLoading: false,
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            }
        },
        setHasError: (state, action) => {
            return {
                ...state,
                hasError: action.payload,
            }
        },
        reset(state) {
            Object.assign(state, initialState)
        },
    },
});

export const equipmentModel = createSelector(
    state => state.equipmentReducer.equipments,
    equipments => equipments.map(equipment => new Equipment(equipment))
);

export const {actions, reducer} = equipmentSlice;

export const {
    setEquipments,
    setIsLoading,
    setHasError,
    reset,
} = actions;
