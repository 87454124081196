import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {CardContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        width: '100%',
    },
    label: {
        display: 'flex',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    formRow: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const CustomCard = ({
    label = '',
    icon = null,
    headerElement = null,
    children,
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.label} component="span" variant="h6">
                    <Grid container spacing={2}>
                        <Grid item>
                            {icon
                                ? <>{icon}</>
                                : null
                            }
                        </Grid>
                        <Grid item>{label}</Grid>
                        <Grid item style={{marginLeft: 'auto'}}>{headerElement}</Grid>
                    </Grid>
                </Typography>
                <div className={classes.formRow}>
                    {children}
                </div>
            </CardContent>
        </Card>
    )
}

CustomCard.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.element,
    headerElement: PropTypes.element,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ]),
}

export default CustomCard;