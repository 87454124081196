import LinkOffIcon from '@material-ui/icons/LinkOff';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import {Collapse, Grid, Typography, makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {red} from '@material-ui/core/colors';
import {setConnectionLost} from '../../store/reducer/app';
import {useTheme} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
    root : {
        backgroundColor: '#C43A3A',
        transition: 'height 0.25s ease-out',
        height: '0',
    },
    render: {
        height: '2.5rem',
    },
}))

/**
 * The purpose of this component is to render an error when we lost the connection.
 * BUT TODO : this component could be more generic and used like the notistack projects.
 * TODO : Used with a color props for example.
 */
const ConnectionLost = ({
    connectionLost,
    style = {},
    dispatch,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useTranslation();

    window.onoffline = () => {
        dispatch(setConnectionLost(true));
    };

    window.ononline = () => {
        dispatch(setConnectionLost(false));
    };

    return (
        <Grid
            container
            // Need to make those classes cause the Collapse component doesn't correctly collapse the Grid and his contents
            // So I make it more manually ...
            className={clsx(classes.root, connectionLost ? classes.render : null)}
            style={style}
            justifyContent="center"
            alignItems="center"
        >
            {/* ... But the classes system (with transition rule) don't collapse the contents correctly */}
            {/* So I use the Collapse component which do it properly */}
            <Collapse in={connectionLost}>
                <Grid container direction="row" style={{color: theme.palette.getContrastText(red[700])}}>
                    <LinkOffIcon style={{transform: 'rotate(-45deg)', margin: '.2rem .5rem 0 0'}}/>
                    <Typography variant="h6">
                        {t('youAreOffLine')}
                    </Typography>
                </Grid>
            </Collapse>
        </Grid>
    )
}

ConnectionLost.propTypes = {
    connectionLost: PropTypes.bool,
    style: PropTypes.object,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    connectionLost: state.appReducer.connectionLost,
}))(ConnectionLost);