import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import {CircularProgress, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const GeneralRatingBadge = ({
    generalState,
    isLoading = false,
}) => {
    const {t} = useTranslation();

    return (
        <Grid container justifyContent="flex-end" alignItems="baseline" spacing={2}>
            <Grid item>
                <Typography variant="body1">
                    <strong>{t('visualRating')}</strong> :
                </Typography>
            </Grid>
            <Grid item>
                {isLoading
                    ? <CircularProgress size={20}/>
                    : // toString() prevent hidden badge when generalState is 0
                    <Badge
                        badgeContent={generalState.toString()}
                        color={generalState < 5 ? 'error' : 'primary'}
                        style={{margin: '0 .8rem 0 .8rem'}}
                    />
                }
            </Grid>
        </Grid>
    );
}

GeneralRatingBadge.propTypes = {
    generalState: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
}

export default GeneralRatingBadge;