import AppBar from '@material-ui/core/AppBar';
import Authenticator, {refreshToken} from './utils/Authenticator';
import ConnectionLost from './components/layout/ConnectionLost';
import Grid from '@material-ui/core/Grid';
import Loading from './components/layout/Loading';
import PrivateRoute from './components/PrivateRoute';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import Version from './components/layout/Version';
import clsx from 'clsx';
import {HTTP_ERROR_404} from './components/error/ErrorPage';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {router} from './router';

const useStyles = makeStyles(theme => ({
    root: {
        transition: 'margin-top 0.25s ease-out',
        marginTop: theme.spacing(5),
    },
    render: {
        marginTop: theme.spacing(10),
    },
}));

const App = ({
    header,
    connectionLost,
    tokenLoaded = false,
    employee,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory()

    useEffect(() => {
        if (!employee.employeeId) {
            history.push(router.login.path);
        }
    }, [true])

    useEffect(() => {
        // 1 800 000 = 30 minutes.
        const interval = setInterval(() => refreshToken(), 1800000);

        return () => clearInterval(interval);
    }, [true])

    return (
        <Grid container direction="column">
            <Grid item>
                <Authenticator/>
                {!tokenLoaded
                    ? <Loading/>
                    : <div>
                        {location.pathname !== router.login.path
                            ? <AppBar position="fixed">
                                {header}
                                <ConnectionLost/>
                            </AppBar>
                            : <ConnectionLost/>
                        }
                        <main className={clsx(
                            classes.root,
                            {[classes.render]: connectionLost}
                        )}>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect
                                        to={router.login.path}
                                        component={router.login.component}
                                    />
                                </Route>
                                <Route exact path={router.login.path} component={router.login.component}/>
                                <PrivateRoute
                                    path={router.vehicleForm.path}
                                    component={router.vehicleForm.component}
                                />
                                <PrivateRoute
                                    path={router.vehicleList.path}
                                    exact={true}
                                    component={router.vehicleList.component}
                                />
                                <PrivateRoute
                                    path={router.login.path}
                                    exact={true}
                                    component={router.login.component}
                                />
                                <Route
                                    path={router.errorPage.path}
                                    component={router.errorPage.component}
                                />
                                <Redirect
                                    to={router.errorPage.formattedPath + HTTP_ERROR_404}
                                    component={router.errorPage.component}
                                />
                            </Switch>
                        </main>
                        <Version/>
                    </div>
                }
            </Grid>
        </Grid>
    );
}

App.propTypes = {
    header: PropTypes.element,
    connectionLost: PropTypes.bool,
    tokenLoaded: PropTypes.bool,
    employee: PropTypes.object,
}

export default connect(state => ({
    header: state.appReducer.header,
    connectionLost: state.appReducer.connectionLost,
    tokenLoaded: state.authenticationReducer.tokenLoaded,
    employee: state.employeeReducer.employee,
}))(App);