import {BaseModel} from 'sjs-base-model';

export default class Interior extends BaseModel {
    code = '';
    name = '';
    firstState = '';
    secondState = '';
    thirdState = '';

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
    }

    /**
     * Use to push a serializable Interior object in the Redux store
     *
     * @returns {{firstState: string, code: string, secondState: string, name: string, thirdState: string}}
     */
    toSerializableObject() {
        return (
            {
                code: this.code,
                name: this.name,
                firstState: this.firstState,
                secondState: this.secondState,
                thirdState: this.thirdState,
            }
        )
    }
}