import ErrorPage from './components/error/ErrorPage';
import Login from './components/Login';
import VehicleForm from './components/VehicleForm';
import VehicleList from './components/VehicleList';

export const router = {
    login: {
        label: 'Login',
        path: '/login',
        component: Login,
    },
    vehicleList: {
        label: 'Liste de vehicle',
        path: '/vehicle-list',
        component: VehicleList,
    },
    vehicleForm: {
        label: 'Formulaire de véhicule',
        path: '/vehicle-form/:id',
        formattedPath: '/vehicle-form/',
        component: VehicleForm,
    },
    errorPage: {
        label: 'Error',
        path: '/error/:code',
        formattedPath: '/error/',
        component: ErrorPage,
    },
};