import PropTypes from 'prop-types';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {router} from '../router';

const PrivateRoute = ({
    component: Component,
    user = null,
    ...rest
}) => {
    return (
        <Route {...rest} exact render={(props) => {
            return (
                user
                    ? <Component {...props} />
                    : <Redirect to={{pathname: router.login.path, state: {referer: props.location}}} />
            )
        }}/>
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.elementType,
    user: PropTypes.object,
    location: PropTypes.object,
}

export default connect(state => ({
    user: state.authenticationReducer.user,
}))(PrivateRoute);