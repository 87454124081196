import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/styles';

const GradientBlueButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(theme.palette.blue.main),
        background:
            'linear-gradient(to top right,' + theme.palette.blue.secondary +', 60%,' + theme.palette.blue.main +')'
        ,
    },
}))(Button);

export default GradientBlueButton;