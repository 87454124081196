import {clearUserData} from '../../utils/Authenticator';
import {createSlice} from '@reduxjs/toolkit';

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        tokenLoaded: false,
        user: null,
    },
    reducers: {
        setUser: (state, action) => {
            if (!action.payload) {
                clearUserData();
            }
            return {
                ...state,
                user: action.payload,
            }
        },
        setTokenLoaded: (state, action) => {
            return {
                ...state,
                tokenLoaded: action.payload,
            }
        },
    },
});

export const {actions, reducer} = authenticationSlice;

export const {
    setUser,
    setTokenLoaded,
} = actions;