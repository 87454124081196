import PropTypes from 'prop-types';
import React from 'react';
import Slider from '@material-ui/core/Slider';

const RestrictedSlider = ({
    defaultValue = 0,
    values = [],
    onChange = () => {},
    disabled = false,
}) => {
    return (
        <Slider
            value={defaultValue}
            style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}
            /*When step is null, the thumb can only be slid onto marks provided with the marks prop.*/
            step={null}
            valueLabelDisplay="auto"
            marks={values}
            onChange={onChange}
            disabled={disabled}
        />
    );
}

RestrictedSlider.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.element,
    defaultValue: PropTypes.number,
    values: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default RestrictedSlider;