import {createSlice} from '@reduxjs/toolkit';

let initialState = {
    controlledLists: {color: [], colorType: [], gearbox: [], doorsAmount: []},
    isLoading: false,
    hasError: false,
    loaded: false,
};

export const controlledListSlice = createSlice({
    name: 'controlledList',
    initialState: initialState,
    reducers: {
        setControlledLists: (state, action) => {
            return {
                ...state,
                controlledLists: action.payload,
                loaded: true,
                isLoading: false,
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            }
        },
        setHasError: (state, action) => {
            return {
                ...state,
                hasError: action.payload,
            }
        },
        reset(state) {
            Object.assign(state, initialState)
        },
    },
});

export const {actions, reducer} = controlledListSlice;

export const {
    setControlledLists,
    setIsLoading,
    setHasError,
    reset,
} = actions;
