import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import {Box, alpha} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {router} from '../../router';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    listBox: {
        width: '100%',
        margin: 0,
        padding: 0,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: '#3C5276',
        overflow: 'auto',
        maxHeight: 500,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            cursor: 'pointer',
        },
    },
}));

const VehicleAutocomplete = ({
    options = [],
    loaded = false,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'vehicle-folders-autocomplete',
        options: options,
        getOptionLabel: option => option.registrationNumber+option.serialNumber,
    });
    const {t} = useTranslation();

    return (
        <div className={classes.search} {...getRootProps()}>
            <div className={classes.searchIcon}>
                <Search/>
            </div>
            <InputBase
                disabled={!loaded}
                placeholder={t('search')}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{'aria-label': 'search', ...getInputProps()}}
            />
            {groupedOptions.length > 0
                ? <div className={classes.listBox}>
                    <List component="ul" {...getListboxProps()}>
                        {groupedOptions.map((option, index) => (
                            <ListItem
                                button
                                {...getOptionProps({option, index})}
                                key={index}
                                onClick={() => {
                                    history.push(router.vehicleForm.formattedPath + option.docEntry);
                                }}
                            >
                                <ListItemText primary={option.registrationNumber + ' - ' + option.commercialType}/>
                                <Box sx={{justifyContent: 'flex-end'}}>
                                    <i>
                                        <ListItemText primary={option.saleDate}/>
                                    </i>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </div>
                : null
            }
        </div>
    );
}

VehicleAutocomplete.propTypes = {
    options: PropTypes.array,
    loaded: PropTypes.bool,
}

export default VehicleAutocomplete;