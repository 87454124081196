import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/styles';

const DangerButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(theme.palette.danger.main),
        backgroundColor: theme.palette.danger.main,
        '&:hover': {
            backgroundColor: theme.palette.danger.secondary,
        },
    },
}))(Button);

export default DangerButton;