import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import React, {useEffect} from 'react';
import {Button, IconButton} from '@material-ui/core';
import {DEFAULT_EMPLOYEE_COLOR, setEmployeeColor} from '../../store/reducer/employee';
import {makeStyles, useTheme} from '@material-ui/styles';
import {red} from '@material-ui/core/colors';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    avatar: {
        height: '30px',
        width: '30px',
        fontSize: '0.9rem',
    },
    noPadding: {
        padding: 0,
    },
    button: {
        padding: theme.spacing(1),
    },
}));

const UserMenu = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const employee = useSelector(state => state.employeeReducer.employee);
    const employeeColor = useSelector(state => state.employeeReducer.employeeColor);
    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (employeeColor === DEFAULT_EMPLOYEE_COLOR) {
            dispatch(setEmployeeColor('#' +  Math.random().toString(16).substr(-6)))
        }
    }, [employeeColor])

    return (
        <>
            <IconButton onClick={handleClick} style={{padding: 0}}>
                <Avatar
                    className={classes.avatar}
                    style={{
                        backgroundColor: employeeColor,
                        color: theme.palette.getContrastText(employeeColor),
                        border: 'solid 1px',
                        borderColor: '#dbdbdb',
                    }}
                >
                    {employee.firstName.substr(0, 1) + employee.lastName.substr(0, 1)}
                </Avatar>
            </IconButton>
            <Popover
                id="user-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List component="nav" className={classes.noPadding}>
                    <ListItem style={{justifyContent: 'center'}}>
                        {employee.firstName + ' ' + employee.lastName}
                    </ListItem>
                    <ListItem className={classes.noPadding}>
                        <Button
                            className={classes.button}
                            onClick={() => dispatch({type: 'USER_LOGOUT'})}
                            style={{color: red[300]}}
                            startIcon={<PowerSettingsNewIcon />}
                            fullWidth
                        >
                            {t('logout')}
                        </Button>
                    </ListItem>
                </List>
            </Popover>
        </>
    );
}

export default UserMenu;