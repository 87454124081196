import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import UserMenu from '../layout/UserMenu';
import VehicleAutocomplete from './VehicleAutocomplete';
import {Chip} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {vehicleFoldersModel} from '../../store/reducer/vehicleFolder';

const VehicleListHeader = ({
    vehicleFoldersLoaded,
}) => {
    const employee = useSelector(state => state.employeeReducer.employee);
    const vehicleFolders = useSelector(vehicleFoldersModel);

    return (
        <Grid container style={{margin: '.5rem'}}>
            <Grid item xs={4} container direction="row">
                <Chip label={employee.storageParc} icon={<RoomIcon/>} color="secondary"/>
            </Grid>
            <Grid item xs={4}>
                <VehicleAutocomplete options={vehicleFolders} loaded={vehicleFoldersLoaded}/>
            </Grid>
            <Grid item xs={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div style={{marginRight: '5rem'}}>
                    <UserMenu/>
                </div>
            </Grid>
        </Grid>
    );
}

VehicleListHeader.propTypes =  {
    vehicleFoldersLoaded: PropTypes.bool.isRequired,
}

export default VehicleListHeader;
