import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import BuildIcon from '@material-ui/icons/Build';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import CustomCard from '../layout/CustomCard';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FormFieldRender, {CHECKBOX_TYPE} from '../layout/FormFieldRender';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {ClickAwayListener, Tooltip} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {aldModel, setHasError, setIsLoading, setNewValueInAld} from '../../store/reducer/ald';
import {
    commentFields, equipmentFields,
    generalStateFields, interiorFields,
    mechanicFields, partlyCarBodyFields,
    tiresData, tiresFields, windshieldAndLightData,
} from '../../utils/fields-provider/VehicleAldFieldsProvider';
import {connect, useSelector} from 'react-redux';
import {generateAldPdf, getAldData} from '../../api-service/AldApi';
import {makeStyles, useTheme} from '@material-ui/styles';
import {partlyCarBodyToAld} from '../../utils/PartlyCarBodyHandler';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
    tooltipBody: {
        maxWidth: 'none',
    },
}));

const VehicleAld = ({
    vehicle,
    innerJoinPartlyCarBodyData,
    isLoading,
    hasError,
    aldLoaded,
    dispatch,
}) => {
    const theme = useTheme();
    const ald = useSelector(aldModel);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const generatePdf = () => {
        generateAldPdf(ald, vehicle.docEntry).catch(() => {
            enqueueSnackbar(
                <Typography style={{marginRight: '.5rem'}}>
                    {t('generatePdfError')}
                </Typography>,
                {variant: 'error', autoHideDuration: 10000}
            );
        });
    }

    useEffect(() => {
        if (!aldLoaded) {
            if (!hasError) {
                getAldData(vehicle.docEntry)
                    .catch(() => {
                        dispatch(setHasError(true));
                        enqueueSnackbar(
                            t('aldDataNotFoundForThisVehicle'),
                            {variant: 'warning', autoHideDuration: 10000}
                        );

                        dispatch(setIsLoading(false));
                    })
                ;
            }
        }
    }, [hasError, aldLoaded]);

    useEffect(() => {
        dispatch(setNewValueInAld({property: 'docEntryVehicle', newValue: vehicle.docEntry}));
        dispatch(setNewValueInAld({property: 'aldSerialNumber', newValue: vehicle.serialNumber}));
    }, [aldLoaded]);

    const tooltipBody = () => {
        return (
            <Grid container spacing={1} direction="column">
                <Grid item container justifyContent="flex-start">
                    <Typography variant="body2">
                        RSP : {t('straighteningWithoutPainting')} ({t('polishableScratch')}/{t('littleShot')})
                    </Typography>
                </Grid>
                <Grid item container justifyContent="flex-start">
                    <Typography variant="body2">
                        P : {t('elementPainting')} ({t('nonPolishableScratch')})
                    </Typography>
                </Grid>
                <Grid item container justifyContent="flex-start">
                    <Typography variant="body2">
                        TP : {t('sheetMetal')} + {t('elementPainting')} ({t('denting')})
                    </Typography>
                </Grid>
                <Grid item container justifyContent="flex-start">
                    <Typography variant="body2">
                        RP : {t('replacement')} + {t('painting')}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    const helpButton = () => {
        return (
            <ClickAwayListener onClickAway={() => {
                setOpen(false);
            }}>
                <Tooltip
                    title={tooltipBody()}
                    PopperProps={{disablePortal: true}}
                    classes={{tooltip: classes.tooltipBody}}
                    onClose={() => {setOpen(false)}}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={open}
                    placement="right"
                    arrow
                >
                    <IconButton
                        size="small"
                        onClick={() => {setOpen(true)}}
                        style={{marginLeft: '.5rem'}}
                    >
                        <HelpIcon/>
                    </IconButton>
                </Tooltip>
            </ClickAwayListener>
        )
    }

    const determinePartStateLabel = (carBodyPart) => {
        let node = null;

        if (typeof carBodyPart[0] !== 'undefined') {
            switch (carBodyPart[0].partState) {
            case 1:
                node = <b style={{color: theme.palette.blue.main}}>{carBodyPart[0].firstState}</b>;
                break;
            case 2:
                node = <b style={{color: theme.palette.danger.main}}>{carBodyPart[0].secondState}</b>;
                break;
            case 3:
                node = <b style={{color: theme.palette.dark.main}}>{carBodyPart[0].thirdState}</b>;
                break;
            default :
                break;
            }
        }

        return node;
    }

    return (
        <>
            {isLoading
                ? <Grid container>
                    <Grid item xs={4} container>
                        <Grid container item style={{margin: '.5rem'}}>
                            <Grid item xs={12} md={6}>
                                <Skeleton
                                    animation="wave"
                                    height={30}
                                    style={{
                                        transform: 'none',
                                        marginRight: '.5rem',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Skeleton
                                    animation="wave"
                                    height={30}
                                    style={{
                                        transform: 'none',
                                        marginLeft: '.5rem',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{margin: '.5rem'}}>
                            <Skeleton
                                animation="wave"
                                height={1626}
                                style={{
                                    transform: 'none',
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={8} container>
                        <Grid item xs={9} style={{margin: '.5rem 0 .5rem 0'}}>
                            <Skeleton
                                animation="wave"
                                style={{
                                    transform: 'none',
                                    height: '100%',
                                    margin: '0 .5rem 0 .5rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3} style={{margin: '.5rem 0 .5rem 0'}}>
                            <Skeleton
                                animation="wave"
                                style={{
                                    transform: 'none',
                                    height: '100%',
                                    margin: '0 .5rem 0 .5rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{margin: '.5rem 0 .5rem 0'}}>
                            <Skeleton
                                animation="wave"
                                style={{
                                    transform: 'none',
                                    height: '100%',
                                    margin: '0 .5rem 0 .5rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{margin: '.5rem 0 .5rem 0'}}>
                            <Skeleton
                                animation="wave"
                                style={{
                                    transform: 'none',
                                    height: '100%',
                                    margin: '0 .5rem 0 .5rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{margin: '.5rem 0 .5rem 0'}}>
                            <Skeleton
                                animation="wave"
                                style={{
                                    transform: 'none',
                                    height: '100%',
                                    margin: '0 .5rem 0 .5rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{margin: '.5rem 0 .5rem 0'}}>
                            <Skeleton
                                animation="wave"
                                style={{
                                    transform: 'none',
                                    height: '100%',
                                    margin: '0 .5rem 0 .5rem',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{margin: '.5rem 0 .5rem 0'}}>
                            <Skeleton
                                animation="wave"
                                style={{
                                    transform: 'none',
                                    height: '100%',
                                    margin: '0 .5rem 0 .5rem',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                : <form>
                    <Grid container>
                        <Grid item xs={4} container>
                            <Grid container item>
                                <Grid item xs={12} md={6} style={{display: 'flex'}}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        style={{margin: '.5rem'}}
                                        onClick={() => {
                                            dispatch(partlyCarBodyToAld(innerJoinPartlyCarBodyData, ald))
                                        }}
                                    >
                                        {t('getPartlyCarBodyData')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} style={{display: 'flex'}}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        fullWidth
                                        style={{margin: '.5rem'}}
                                        onClick={generatePdf}
                                    >
                                        {t('generatePdf')}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <CustomCard label={t('partlyCarBody')} headerElement={helpButton()} icon={<DriveEtaIcon/>}>
                                    {partlyCarBodyFields().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            type={field.type}
                                            field={field.field}
                                            value={ald[field.field]}
                                            choices={field.choices ? field.choices : []}
                                            onChangeAction={(e) => {
                                                dispatch(setNewValueInAld({
                                                    property: field.field,
                                                    newValue: e.target.value,
                                                }))
                                            }}
                                        />
                                    )}
                                </CustomCard>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} container>
                            <Grid item xs={9} style={{display: 'flex'}}>
                                <CustomCard label={t('mechanic')} icon={<BuildIcon/>}>
                                    {mechanicFields().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            type={field.type}
                                            field={field.field}
                                            value={ald[field.field]}
                                            choices={field.choices ? field.choices : []}
                                            onChangeAction={(e) => {
                                                dispatch(setNewValueInAld({
                                                    property: field.field,
                                                    newValue: e.target.value,
                                                }))
                                            }}
                                        />
                                    )}
                                </CustomCard>
                            </Grid>
                            <Grid item xs={3} style={{display: 'flex'}}>
                                <CustomCard label={t('generalState')} icon={<CheckIcon/>}>
                                    {generalStateFields().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            type={field.type}
                                            field={field.field}
                                            value={ald[field.field]}
                                            choices={field.choices ? field.choices : []}
                                            onChangeAction={(e) => {
                                                dispatch(setNewValueInAld({
                                                    property: field.field,
                                                    newValue: e.target.value,
                                                }))
                                            }}
                                        />
                                    )}
                                </CustomCard>
                            </Grid>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <CustomCard
                                    label={t('windshield') + ' ' + t('and') + ' ' + t('light')}
                                    icon={<FormatListBulletedIcon/>}
                                >
                                    <Grid container spacing={3} style={{marginTop: '.5rem'}}>
                                        {windshieldAndLightData(innerJoinPartlyCarBodyData, theme).map((item, key) =>
                                            <Grid item xs={6} lg={3} key={key}>
                                                <Typography><b>{item.label}</b></Typography>
                                                {item.parts.map((item, key) =>
                                                    <Typography key={key}>
                                                        <span>{item.label + ' : '}</span>
                                                        {determinePartStateLabel(item.value)}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                </CustomCard>
                            </Grid>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <CustomCard label={t('tires')} icon={<FormatListBulletedIcon/>}>
                                    <Grid container spacing={3} style={{marginTop: '.5rem'}}>
                                        {tiresData(innerJoinPartlyCarBodyData, theme).map((item, key) =>
                                            <Grid item xs={12} md={6} lg={3} key={key}>
                                                {item.parts.map((item, key) =>
                                                    <Typography key={key}>
                                                        <span>
                                                            {item.label + ' : '}
                                                        </span>
                                                        {determinePartStateLabel(item.value)}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid container spacing={3} style={{marginTop: '.5rem'}}>
                                        <Grid item xs={12}>
                                            {tiresFields().map((field, index) =>
                                                <FormFieldRender
                                                    key={index}
                                                    label={field.label}
                                                    type={field.type}
                                                    field={field.field}
                                                    value={ald[field.field]}
                                                    choices={field.choices ? field.choices : []}
                                                    onChangeAction={(e) => {
                                                        dispatch(setNewValueInAld({
                                                            property: field.field,
                                                            newValue: e.target.value,
                                                        }))
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </CustomCard>
                            </Grid>
                            <Grid item xs={6} style={{display: 'flex'}}>
                                <CustomCard label={t('equipment')} icon={<FormatListBulletedIcon/>}>
                                    <Grid container spacing={3} style={{marginTop: '.5rem'}}>
                                        <Grid item xs={12}>
                                            <span>{t('duplicateKeys') + ' : '}</span>
                                            {vehicle.keyType === 'N'
                                                ? <b style={{color: theme.palette.danger.main}}>{t('no')}</b>
                                                : <b style={{color: theme.palette.success.main}}>{t('yes')}</b>
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span>{t('maintenanceBook') + ' : '}</span>
                                            {vehicle.maintenanceBook
                                                ? <b style={{color: theme.palette.danger.main}}>{t('no')}</b>
                                                : <b style={{color: theme.palette.success.main}}>{t('yes')}</b>
                                            }
                                        </Grid>
                                    </Grid>
                                    {equipmentFields(ald).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            type={field.type}
                                            field={field.field}
                                            value={ald[field.field]}
                                            choices={field.choices ? field.choices : []}
                                            onChangeAction={(e) => {
                                                dispatch(setNewValueInAld({
                                                    property: field.field,
                                                    newValue: field.type === CHECKBOX_TYPE ? e.target.checked : e.target.value,
                                                }))
                                            }}
                                        />
                                    )}
                                </CustomCard>
                            </Grid>
                            <Grid item xs={6} style={{display: 'flex'}}>
                                <CustomCard label={t('interior')} icon={<AirlineSeatReclineExtraIcon/>}>
                                    {interiorFields(ald).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            type={field.type}
                                            field={field.field}
                                            value={ald[field.field]}
                                            choices={field.choices ? field.choices : []}
                                            onChangeAction={(e) => {
                                                dispatch(setNewValueInAld({
                                                    property: field.field,
                                                    newValue: field.type === CHECKBOX_TYPE ? e.target.checked : e.target.value,
                                                }))
                                            }}
                                        />
                                    )}
                                </CustomCard>
                            </Grid>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <CustomCard label={t('comment')} icon={<CreateIcon/>}>
                                    {commentFields(ald).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            type={field.type}
                                            field={field.field}
                                            label={field.label}
                                            value={ald[field.field]}
                                            choices={field.choices ? field.choices : []}
                                            onChangeAction={(e) => {
                                                dispatch(setNewValueInAld({
                                                    property: field.field,
                                                    newValue: e.target.value,
                                                }))
                                            }}
                                        />
                                    )}
                                </CustomCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            }
        </>
    )
}

VehicleAld.propTypes = {
    vehicle: PropTypes.object.isRequired,
    innerJoinPartlyCarBodyData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    aldLoaded: PropTypes.bool,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    isLoading: state.aldReducer.isLoading,
    hasError: state.aldReducer.hasError,
    aldLoaded: state.aldReducer.aldLoaded,
}))(VehicleAld);