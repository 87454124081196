import {clone} from 'ramda';
import {setNewValueInAld} from '../store/reducer/ald';
import {setNewValueInVehicle} from '../store/reducer/vehicle';

const imgPath = '/img/partly-car-body/';

export const getGifSrc = (carBodyParts, selectedPartCode) => {
    let currentGif = imgPath + 'EBlanc' + selectedPartCode + '.gif';

    if (carBodyParts.length !== 0) {
        carBodyParts.map(carBodyPart => {
            if (carBodyPart.partCode === selectedPartCode) {
                switch (carBodyPart.partState) {
                case 1:
                    currentGif = imgPath + 'EBleu' + selectedPartCode + '.gif';
                    break;
                case 2:
                    currentGif = imgPath + 'ERouge' + selectedPartCode + '.gif';
                    break;
                case 3:
                    currentGif = imgPath + 'ENoir' + selectedPartCode + '.gif';
                    break;
                default:
                    currentGif = imgPath + 'EBlanc' + selectedPartCode + '.gif';
                    break;
                }
            }
        })
    }

    return currentGif
}

export const changeGifColor = (simplifiedCarBodyParts, selectedPartCode) => dispatch => {
    const glassParts = ['07', '16', '11', '17', '18', '24', '27', '28', '29', '39', '40'];
    let found = false;

    // When the partlyCarBody is empty, simply add the selected part to the array
    if (simplifiedCarBodyParts.length === 0) {
        let clonedSimplifiedCarBodyParts = clone(simplifiedCarBodyParts);
        clonedSimplifiedCarBodyParts.push({
            partCode: selectedPartCode,
            partState: 1,
            files: [],
        });
        dispatch(setNewValueInVehicle(
            {property: 'simplifiedCarBodyParts', newValue: clonedSimplifiedCarBodyParts}
        ));
        found = true;
    } else {
        // Else fetch the array and try to found the selected part in it
        simplifiedCarBodyParts.map((carBodyPart, key) => {
            if (carBodyPart.partCode === selectedPartCode) {
                found = true
                if (glassParts.includes(selectedPartCode)) {
                    if (carBodyPart.partState === 2) {
                        let clonedSimplifiedCarBodyParts = clone(simplifiedCarBodyParts);
                        clonedSimplifiedCarBodyParts.splice(key, 1);
                        dispatch(setNewValueInVehicle(
                            {property: 'simplifiedCarBodyParts', newValue: clonedSimplifiedCarBodyParts}
                        ));
                    } else {
                        let clonedSimplifiedCarBodyParts = clone(simplifiedCarBodyParts);
                        clonedSimplifiedCarBodyParts[key] = {
                            partCode: selectedPartCode,
                            partState: carBodyPart.partState + 1,
                            files: carBodyPart.files,
                        };
                        dispatch(setNewValueInVehicle(
                            {property: 'simplifiedCarBodyParts', newValue: clonedSimplifiedCarBodyParts}
                        ));
                    }
                } else {
                    if (carBodyPart.partState === 3) {
                        let clonedSimplifiedCarBodyParts = clone(simplifiedCarBodyParts);
                        clonedSimplifiedCarBodyParts.splice(key, 1);
                        dispatch(setNewValueInVehicle(
                            {property: 'simplifiedCarBodyParts', newValue: clonedSimplifiedCarBodyParts}
                        ));
                    } else {
                        let clonedSimplifiedCarBodyParts = clone(simplifiedCarBodyParts);
                        clonedSimplifiedCarBodyParts[key] = {
                            partCode: selectedPartCode,
                            partState: carBodyPart.partState + 1,
                            files: carBodyPart.files,
                        };
                        dispatch(setNewValueInVehicle(
                            {property: 'simplifiedCarBodyParts', newValue: clonedSimplifiedCarBodyParts}
                        ));
                    }
                }
            }
        })

        // If the selected part hasn't been found add it to the array
        if (!found) {
            let clonedSimplifiedCarBodyParts = clone(simplifiedCarBodyParts);
            clonedSimplifiedCarBodyParts.push({
                partCode: selectedPartCode,
                partState: 1,
                files: [],
            });
            dispatch(setNewValueInVehicle(
                {property: 'simplifiedCarBodyParts', newValue: clonedSimplifiedCarBodyParts}
            ));
        }
    }
}

export const partlyCarBodyToAld = (carBodyParts) => dispatch => {
    carBodyParts.forEach(carBodyPart => {
        const frontBumpers = carBodyParts.filter(carBodyPart => carBodyPart.code === '23' || carBodyPart.code === '02')
        const backBumpers = carBodyParts.filter(carBodyPart => carBodyPart.code === '25' || carBodyPart.code === '05')

        switch (carBodyPart.code) {
        // 23 : Left front bumper
        // 02 : Right front bumper
        case '23':
        case '02':
            dispatch(setNewValueInAld({property: 'frontBumper', newValue: maxPartState(frontBumpers).toString()}));
            break;
        // 25 : Left back bumper
        // 05 : Right back bumper
        case '25':
        case '05':
            dispatch(setNewValueInAld({property: 'backBumper', newValue: maxPartState(backBumpers).toString()}));
            break;
        case '19':
            dispatch(setNewValueInAld({property: 'carTrunk', newValue: carBodyPart.partState.toString()}));
            break;
        case '08':
            dispatch(setNewValueInAld({property: 'rightFrontFender', newValue: carBodyPart.partState.toString()}));
            break;
        case '09':
            dispatch(setNewValueInAld({property: 'rightSill', newValue: carBodyPart.partState.toString()}));
            break;
        case '10':
            dispatch(setNewValueInAld({property: 'rightBackFender', newValue: carBodyPart.partState.toString()}));
            break;
        case '12':
            dispatch(setNewValueInAld({property: 'rightFrontDoor', newValue: carBodyPart.partState.toString()}));
            break;
        case '13':
            dispatch(setNewValueInAld({property: 'rightBackDoor', newValue: carBodyPart.partState.toString()}));
            break;
        case '14':
            dispatch(setNewValueInAld({property: 'carHood', newValue: carBodyPart.partState.toString()}));
            break;
        case '21':
            dispatch(setNewValueInAld({property: 'carRoof', newValue: carBodyPart.partState.toString()}));
            break;
        case '30':
            dispatch(setNewValueInAld({property: 'leftFrontFender', newValue: carBodyPart.partState.toString()}));
            break;
        case '31':
            dispatch(setNewValueInAld({property: 'leftFrontDoor', newValue: carBodyPart.partState.toString()}));
            break;
        case '32':
            dispatch(setNewValueInAld({property: 'leftBackDoor', newValue: carBodyPart.partState.toString()}));
            break;
        case '33':
            dispatch(setNewValueInAld({property: 'leftBackFender', newValue: carBodyPart.partState.toString()}));
            break;
        case '34':
            dispatch(setNewValueInAld({property: 'leftSill', newValue: carBodyPart.partState.toString()}));
            break;
        case '38':
            dispatch(setNewValueInAld({property: 'rightMirror', newValue: carBodyPart.partState.toString()}));
            break;
        case '42':
            dispatch(setNewValueInAld({property: 'leftMirror', newValue: carBodyPart.partState.toString()}));
            break;
        }
    });
}

/**
 * Return the max partState between TWO parts
 *
 * @returns {number|*}
 */
let maxPartState = (parts) => {
    if (parts[1] !== undefined) {
        if (parts[0].partState >= parts[1].partState) {
            return parts[0].partState;
        } else {
            return parts[1].partState;
        }
    } else {
        return parts[0].partState;
    }
};