import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import {reducer as aldReducer} from './reducer/ald';
import {reducer as appReducer} from './reducer/app';
import {reducer as authenticationReducer} from './reducer/authentication';
import {clearUserData} from '../utils/Authenticator';
import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {reducer as controlledListReducer} from './reducer/controlledList';
import {reducer as datatableReducer} from './reducer/datatable';
import {reducer as employeeReducer} from './reducer/employee';
import {reducer as equipmentReducer} from './reducer/equipment';
import {reducer as formErrorReducer} from './reducer/formError';
import {reducer as modalReducer} from './reducer/modal';
import {persistReducer} from 'redux-persist';
import {reducer as snackbarReducer} from './reducer/snackbar';
import {reducer as vehicleAnnexeDataReducer} from './reducer/vehicleAnnexeData';
import {reducer as vehicleFolderReducer} from './reducer/vehicleFolder';
import {reducer as vehicleReducer} from './reducer/vehicle';

const combinedReducer = combineReducers({
    aldReducer,
    authenticationReducer,
    appReducer,
    controlledListReducer,
    datatableReducer,
    employeeReducer,
    equipmentReducer,
    formErrorReducer,
    modalReducer,
    snackbarReducer,
    vehicleAnnexeDataReducer,
    vehicleFolderReducer,
    vehicleReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
        clearUserData();
    }
    return combinedReducer(state, action);
};

const persistConfig = {
    key: 'root',
    // The storage which is used here.
    storage: storageSession,
    // Only this reducer will be persisted.
    whitelist: ['employeeReducer', 'controlledListReducer', 'vehicleAnnexeDataReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
    reducer: persistedReducer,
    // Useful cause the persistedReducer is a function, and Thunk allow putting function in store.
    middleware: [thunk],
});
