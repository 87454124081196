import {BaseModel} from 'sjs-base-model';
import {array, func, object} from 'prop-types';

export const ONCLICK_FUNCTION_TYPE = 0;
export const ONCLICK_DISPATCH_TYPE = 1;

export default class RefreshButtonOnClickAction extends BaseModel {
    type = ONCLICK_FUNCTION_TYPE || ONCLICK_DISPATCH_TYPE;
    action = object || func;
    parameters = array;

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
    }
}