import PropTypes from 'prop-types';
import React from 'react';
import {changeGifColor, getGifSrc} from '../../../utils/PartlyCarBodyHandler';
import {makeStyles} from '@material-ui/styles';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(() => ({
    partlyCarBodyTable: {
        lineHeight: '0',
        borderCollapse: 'collapse',
        padding: '0',
    },
}))

const PartlyCarBody = ({
    vehicle,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const spacer = '/img/partly-car-body/spacer.gif';

    return (
        <table className={classes.partlyCarBodyTable} id="partlyCarBody">
            <tbody>
                <tr>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '01'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '01')} width="27" height="145" alt="01.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '39'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '39')} width="27" height="145" alt="39.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '02'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '02')} width="37" height="145" alt="02.gif"/>
                    </td>
                    <td colSpan="3" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '37'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '37')} width="105" height="48" alt="37.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '38'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '38')} width="62" height="48" alt="38.gif"/>
                    </td>
                    <td colSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '04'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '04')} width="163" height="48" alt="04.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '05'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '05')} width="38" height="145" alt="05.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '06'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '06')} width="28" height="145" alt="06.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="48" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td rowSpan="3" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '07'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '07')} width="26" height="71" alt="07.gif"/>
                    </td>
                    <td colSpan="2" rowSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '08'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '08')} width="79" height="55" alt="08.gif"/>
                    </td>
                    <td colSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '09'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '09')} width="126" height="18" alt="09.gif"/>
                    </td>
                    <td colSpan="2" rowSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '10'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '10')} width="68" height="55" alt="10.gif"/>
                    </td>
                    <td rowSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '11'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '11')} width="31" height="97" alt="11.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="18" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '12'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '12')} width="62" height="37" alt="12.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '13'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '13')} width="64" height="37" alt="13.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="37" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td rowSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '14'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '14')} width="56" height="90" alt="14.gif"/>
                    </td>
                    <td rowSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '15'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '15')} width="23" height="90" alt="15.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '16'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '16')} width="62" height="16" alt="16.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '17'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '17')} width="64" height="16" alt="17.gif"/>
                    </td>
                    <td rowSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '18'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '18')} width="21" height="90" alt="18.gif"/>
                    </td>
                    <td rowSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '19'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '19')} width="47" height="90" alt="19.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="16" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td rowSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '20'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '20')} width="26" height="58" alt="20.gif"/>
                    </td>
                    <td colSpan="2" rowSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '21'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '21')} width="126" height="58" alt="21.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="26" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '22'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '22')} width="27" height="153" alt="22.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '40'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '40')} width="27" height="153" alt="40.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '23'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '23')} width="37" height="153" alt="23.gif"/>
                    </td>
                    <td rowSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '24'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '24')} width="31" height="105" alt="24.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '25'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '25')} width="38" height="153" alt="25.gif"/>
                    </td>
                    <td rowSpan="5" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '26'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '26')} width="28" height="153" alt="26.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="32" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td rowSpan="3" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '27'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '27')} width="26" height="73" alt="27.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '28'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '28')} width="62" height="16" alt="28.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '29'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '29')} width="64" height="16" alt="29.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="16" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" rowSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '30'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '30')} width="79" height="57" alt="30.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '31'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '31')} width="62" height="39" alt="31.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '32'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '32')} width="64" height="39" alt="32.gif"/>
                    </td>
                    <td colSpan="2" rowSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '33'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '33')} width="68" height="57" alt="33.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="39" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '34'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '34')} width="126" height="18" alt="34.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="18" alt="spacer.gif"/>
                    </td>
                </tr>
                <tr>
                    <td colSpan="3" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '41'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '41')} width="105" height="48" alt="41.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '42'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '42')} width="62" height="48" alt="42.gif"/>
                    </td>
                    <td colSpan="4" className={classes.partlyCarBodyTable} onClick={() => {
                        dispatch(changeGifColor(vehicle.simplifiedCarBodyParts, '36'))
                    }}>
                        <img src={getGifSrc(vehicle.simplifiedCarBodyParts, '36')} width="163" height="48" alt="36.gif"/>
                    </td>
                    <td className={classes.partlyCarBodyTable}>
                        <img src={spacer} width="1" height="48" alt="spacer.gif"/>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

PartlyCarBody.propTypes = {
    vehicle: PropTypes.object.isRequired,
}

export default PartlyCarBody;