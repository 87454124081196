import {BaseModel} from 'sjs-base-model';

export default class Equipment extends BaseModel {
    docEntry = '';
    code = '';
    shortName = '';
    longName = '';
    weight = 0;
    defective = false;

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
    }
}