import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/styles';

const SuccessButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText(theme.palette.success.main),
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.secondary,
        },
    },
}))(Button);

export default SuccessButton;