import {apiGet} from './AbstractApi';
import {getStore} from '../index';
import {setControlledLists, setHasError, setIsLoading} from '../store/reducer/controlledList';

export const getControlledLists = () => {
    getStore().dispatch(setIsLoading(true));
    return apiGet('/api/fleet-statement/controlled-lists')
        .then(response => {
            getStore().dispatch(setControlledLists(response));
        })
        .catch(error => {
            getStore().dispatch(setHasError(true));
            throw new Error(error.message);
        })
    ;
}
