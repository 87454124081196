import Carousel from 'react-material-ui-carousel';
import IconButton from '@material-ui/core/IconButton';
import Modal from '../../layout/Modal';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NotFullyGenericFileUploader from '../../layout/NotFullyGenericFileUploader';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {TableCell, makeStyles} from '@material-ui/core';
import {capitalizeFirstLetter} from '../../../utils/Misc';
import {isNil} from 'ramda';
import {setOpenModalId} from '../../../store/reducer/modal';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: '100%',
        maxHeight: '70vh',
    },
}))

const CarBodyDescription = ({
    vehicle,
    innerJoinPartlyCarBodyData,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    function modalContent(carBodyPart) {
        return (
            <Carousel
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                NextIcon={<NavigateNextIcon/>}
                PrevIcon={<NavigateBeforeIcon/>}
            >
                {carBodyPart.files.map((file, key) => (
                    <img src={file.file} alt={file.fileName} key={key} className={classes.image}/>
                ))}
            </Carousel>
        )
    }

    return (
        <Table size="small" padding="none" aria-label="partly car body data">
            <TableBody>
                {innerJoinPartlyCarBodyData.map(carBodyPart => (
                    <TableRow
                        key={carBodyPart.code}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <TableCell style={{width: '25rem'}}>
                            - {capitalizeFirstLetter(carBodyPart.name)}
                        </TableCell>
                        <TableCell style={{width: '100%'}}>
                            {carBodyPart.partState === 1
                                ? <span>{carBodyPart.firstState}</span>
                                : carBodyPart.partState === 2
                                    ? <span>{carBodyPart.secondState}</span>
                                    : carBodyPart.partState === 3
                                        ? <span>{carBodyPart.thirdState}</span>
                                        : null
                            }
                        </TableCell>
                        <TableCell align="right">
                            {!isNil(carBodyPart.files) && carBodyPart.files.length >= 1
                                ? <>
                                    <IconButton
                                        style={{padding: 0}}
                                        onClick={() => {dispatch(setOpenModalId(carBodyPart.code))}}
                                    >
                                        <VisibilityIcon fontSize="small"/>
                                    </IconButton>
                                    <Modal modalId={carBodyPart.code}>
                                        {modalContent(carBodyPart)}
                                    </Modal>
                                </>
                                : <span style={{display: 'block', width: '1.3rem'}}>{null}</span>
                            }
                        </TableCell>
                        <TableCell align="right" style={{display: 'flex', width: '14.2rem'}}>
                            <NotFullyGenericFileUploader
                                code={carBodyPart.code}
                                docNum={vehicle.docNum}
                                property="simplifiedCarBodyParts"
                                providedArray={vehicle.simplifiedCarBodyParts}
                            />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

CarBodyDescription.propTypes = {
    vehicle: PropTypes.object.isRequired,
    innerJoinPartlyCarBodyData: PropTypes.array.isRequired,
}

export default CarBodyDescription;