import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import RefreshButtonOnClickAction, {
    ONCLICK_DISPATCH_TYPE,
    ONCLICK_FUNCTION_TYPE,
} from '../../model/RefreshButtonOnClickAction';
import RefreshIcon from '@material-ui/icons/Refresh';
import {IconButton, Zoom} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles (() => ({
    fullRotate: {
        transform: 'rotate(360deg)',
    },
}));

export const ICON_BUTTON_TYPE = 0;
export const STANDARD_BUTTON_TYPE = 1;

/**
 * Two ways to call RefreshButton onclick action type :
 * - <RefreshButton
 *       onClickActions={[
 *           new RefreshButtonOnClickAction(
 *               {type: ONCLICK_FUNCTION_TYPE, action: setYourLocalState, parameters: [false]}
 *           )
 *       ]}
 *   />
 *   This solution allow you to passing, for example, a local state function.
 *   Care to DON'T PUT parenthesis when you instantiate the action function.
 *   In this case the parameters of the function need to be instantiate in the parameters fields.
 *
 * - <RefreshButton
 *       onClickActions={[
 *           new RefreshButtonOnClickAction(
 *               {type: ONCLICK_DISPATCH_TYPE, action: setYourGlobalState(yourBeautifulParam)}
 *           )
 *       ]}
 *   />
 *   This solution allow you to passing, for example, a global state function which will be called with dispatch.
 *   Care to PUT parenthesis when you instantiate the action to dispatch.
 *   In this case the parameters need to be passed like a normal function call.
 */
const RefreshButton = ({
    size = 'medium',
    color = 'primary',
    variant = 'contained',
    type = ICON_BUTTON_TYPE,
    onClickActions = [RefreshButtonOnClickAction],
    isDisplayed = true,
    transitionAppear = true,
    disabled = false,
    fullWidth = false,
}) => {
    const classes = useStyles();
    const [isRefreshButtonClicked, setIsRefreshButtonClicked] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    switch (type) {
    case ICON_BUTTON_TYPE:
        return (
            <div
                className={isRefreshButtonClicked ? classes.fullRotate : null}
                style={isRefreshButtonClicked ? {transition: 'transform .7s ease'} : null}
            >
                <Zoom in={isDisplayed} appear={transitionAppear}>
                    <IconButton
                        size={size}
                        color={color}
                        onClick={() => {
                            setIsRefreshButtonClicked(true);
                            // Reset the state after the animation duration
                            setTimeout(() => {
                                setIsRefreshButtonClicked(false);
                                onClickActions.forEach(onClickAction => {
                                    if (onClickAction.type === ONCLICK_FUNCTION_TYPE) {
                                        onClickAction.action(...onClickAction.parameters);
                                    } else if (onClickAction.type === ONCLICK_DISPATCH_TYPE) {
                                        dispatch(onClickAction.action);
                                    }
                                })

                            }, 600);
                        }}
                        disabled={disabled}
                    >
                        <RefreshIcon/>
                    </IconButton>
                </Zoom>
            </div>
        )
    case STANDARD_BUTTON_TYPE:
        return (
            <Zoom in={isDisplayed} appear={transitionAppear}>
                <Button
                    size={size}
                    color={color}
                    variant={variant}
                    onClick={() => {
                        setIsRefreshButtonClicked(true);
                        // Reset the state after the animation duration
                        setTimeout(() => {
                            onClickActions.forEach(onClickAction => {
                                if (onClickAction.type === ONCLICK_FUNCTION_TYPE) {
                                    onClickAction.action(...onClickAction.parameters);
                                } else if (onClickAction.type === ONCLICK_DISPATCH_TYPE) {
                                    dispatch(onClickAction.action);
                                }
                            })

                            setIsRefreshButtonClicked(false);
                        }, 600);
                    }}
                    disabled={disabled}
                    startIcon={
                        <RefreshIcon
                            className={isRefreshButtonClicked ? classes.fullRotate : null}
                            style={isRefreshButtonClicked ? {transition: 'transform .7s ease'} : null}
                        />
                    }
                    fullWidth={fullWidth}
                >
                    {t('reload')}
                </Button>
            </Zoom>
        )
    }
}

RefreshButton.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    type: PropTypes.number,
    onClickActions: PropTypes.array,
    isDisplayed: PropTypes.bool,
    transitionAppear: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.any,
}

export default RefreshButton;