import Ald from '../../model/Ald';
import {assoc} from 'ramda';
import {createSelector, createSlice} from '@reduxjs/toolkit';

const initialState = {
    ald: {},
    isLoading: false,
    aldLoaded: false,
    isPersisting: false,
    hasError: false,
    apiResult: {},
    apiResultPdf: {},
};

export const aldSlice = createSlice({
    name: 'ald',
    initialState: initialState,
    reducers: {
        setAld: (state, action) => {
            return {
                ...state,
                ald: action.payload,
                aldLoaded: true,
                isLoading: false,
            }
        },
        setNewValueInAld: (state, action) => {
            return {
                ...state,
                ald: assoc(action.payload.property, action.payload.newValue, state.ald),
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            }
        },
        setAldLoaded: (state, action) => {
            return {
                ...state,
                aldLoaded: action.payload,
            }
        },
        setIsPersisting: (state, action) => {
            return {
                ...state,
                isPersisting: action.payload,
            }
        },
        setHasError: (state, action) => {
            return {
                ...state,
                hasError: action.payload,
            }
        },
        setApiResult: (state, action) => {
            return {
                ...state,
                apiResult: action.payload,
            }
        },
        setApiResultPdf: (state, action) => {
            return {
                ...state,
                apiResultPdf: action.payload,
            }
        },
        reset(state) {
            Object.assign(state, initialState)
        },
    },
});

export const aldModel = createSelector(
    state => state.aldReducer.ald,
    ald => new Ald(ald)
);

export const {actions, reducer} = aldSlice;

export const {
    setAld,
    setNewValueInAld,
    setIsLoading,
    setAldLoaded,
    setIsPersisting,
    setHasError,
    setApiResult,
    setApiResultPdf,
    reset,
} = actions;
