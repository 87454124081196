import {apiGet} from '../api-service/AbstractApi';
import {connect} from 'react-redux';
import {setTokenLoaded, setUser} from '../store/reducer/authentication';
import {useEffect} from 'react';

const Authenticator = ({
    tokenLoaded = false,
    dispatch,
}) => {
    useEffect(() => {
        if (!tokenLoaded) {
            dispatch(fetchTokenAndFindUser())
        }
    }, [tokenLoaded]);

    return null;
};

export default connect(state => ({
    tokenLoaded: state.authenticationReducer.tokenLoaded,
}))(Authenticator);

const ClientOauth2 = require('client-oauth2');
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const EXPIRES_KEY = 'expires';

const appAuth = new ClientOauth2({
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    clientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    accessTokenUri: process.env.REACT_APP_API_URL + '/token',
    authorizationUri: process.env.REACT_APP_API_URL + '/authorize',
    scopes: [],
});

export const getClientData = () => {
    return {
        accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
        refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
        expires: localStorage.getItem(EXPIRES_KEY),
    }
}

export const clearUserData = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(EXPIRES_KEY);
}

export const refreshToken = () => {
    let clientData = getClientData();
    let token = appAuth.createToken(clientData.accessToken, clientData.refreshToken, {});

    return token.refresh()
        .then(token => {
            localStorage.setItem(ACCESS_TOKEN_KEY, token.accessToken);
            localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
            localStorage.setItem(EXPIRES_KEY, token.expires);
        })
        .catch(e => console.log('------- ERROR REFRESH TOKEN ------- ' + e))
    ;
}

export const fetchTokenAndFindUser = () => dispatch => {
    let clientData = getClientData();

    if (!clientData.refreshToken) {
        dispatch(setTokenLoaded(true));

        return;
    }

    // (Has refresh token = user) Reload token if needed and load user
    if (clientData.accessToken && clientData.refreshToken && new Date(clientData.expires) < new Date()) {
        refreshToken()
            .then(() => {
                fetchUser()
                    .then(user => {
                        dispatch(setUser(user))
                        dispatch(setTokenLoaded(true));
                    })
                    .catch(() => {
                        clearUserData();
                        dispatch(fetchTokenAndFindUser());
                    })
                ;
            });

        return;
    }

    // Get user
    if (clientData.accessToken && new Date(clientData.expires) > new Date()) {
        fetchUser()
            .then(user => {
                dispatch(setUser(user));
                dispatch(setTokenLoaded(true));
            })
            .catch(() => {
                clearUserData();
                dispatch(fetchTokenAndFindUser());
            })
        ;
    }
};

export const fetchUserToken = (username, password, dispatch) => {
    return appAuth.owner.getToken(
        username,
        password,
        {
            body: {
                grant_type: process.env.REACT_APP_GRANT_TYPE,
                company_db: process.env.REACT_APP_COMPANY_DB,
            },
        })
        .then(token => {
            localStorage.setItem(ACCESS_TOKEN_KEY, token.accessToken);
            localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
            localStorage.setItem(EXPIRES_KEY, token.expires);
            dispatch(setTokenLoaded(true));
        })
        .then(() => {
            return fetchUser();
        })
        .then(user => {
            dispatch(setUser(user));
        })
    ;
}

export const fetchUser = () => {
    return apiGet('/api/connected_user')
        .then(response => {
            return response;
        })
    ;
}