import Employee from '../../model/Employee';
import {createSelector, createSlice} from '@reduxjs/toolkit';

export const DEFAULT_EMPLOYEE_COLOR = '#dbdbdb';
let initialState = {
    employees: [],
    employee: {},
    isLoading: false,
    hasError: false,
    loaded: false,
    employeeColor: DEFAULT_EMPLOYEE_COLOR,
};

export const employeeSlice = createSlice({
    name: 'employee',
    initialState: initialState,
    reducers: {
        setEmployees: (state, action) => {
            return {
                ...state,
                employees: action.payload,
                loaded: true,
                isLoading: false,
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            }
        },
        setHasError: (state, action) => {
            return {
                ...state,
                hasError: action.payload,
            }
        },
        setEmployee: (state, action) => {
            return {
                ...state,
                employee: action.payload,
            }
        },
        setEmployeeColor: (state, action) => {
            return {
                ...state,
                employeeColor: action.payload,
            }
        },
        reset(state) {
            Object.assign(state, initialState)
        },
    },
});

export const employeesModel = createSelector(
    state => state.employeeReducer.employees,
    employees => employees.map(employee => new Employee(employee))
);

export const employeeModel = createSelector(
    state => state.employeeReducer.employee,
    employee => new Employee(employee)
);

export const {actions, reducer} = employeeSlice;

export const {
    setEmployees,
    setIsLoading,
    setHasError,
    setEmployee,
    setEmployeeColor,
    reset,
} = actions;
