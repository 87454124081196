import React from 'react';
import {createSlice} from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        header: <></>,
        connectionLost: false,
    },
    reducers: {
        setConnectionLost: (state, action) => {
            return {
                ...state,
                connectionLost: action.payload,
            }
        },
        setHeader: (state, action) => {
            return {
                ...state,
                header: action.payload,
            }
        },
    },
});

export const {actions, reducer} = appSlice;

export const {
    setConnectionLost,
    setHeader,
} = actions;