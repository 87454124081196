import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import UserMenu from '../layout/UserMenu';
import {Link} from 'react-router-dom';
import {Tab, Tabs} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {router} from '../../router';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {vehicleModel} from '../../store/reducer/vehicle';

const useStyles = makeStyles(() => ({
    cornerTab: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    linkCorner: {
        color: '#fff',
    },
    tab: {
        backgroundColor: 'transparent !important',
    },
}));


function a11yProps(index) {
    return {
        id: `vehicle-form-tab-${index}`,
        'aria-controls': `vehicle-form-tabpanel-${index}`,
    };
}

const VehicleFormHeader = ({
    handleChange,
    tabNumber,
}) => {
    const classes = useStyles();
    const vehicle = useSelector(vehicleModel);
    const {t} = useTranslation();

    return (
        <Grid container>
            <Grid item xs={1} className={classes.cornerTab}>
                <Link to={router.vehicleList.path} className={classes.linkCorner}>
                    <ArrowBackIosIcon/>
                </Link>
            </Grid>
            <Grid item xs={10}>
                <Tabs
                    className={classes.tab}
                    value={tabNumber}
                    onChange={handleChange}
                    aria-label="vehicle-form-tabs"
                    variant="fullWidth"
                >
                    <Tab label={t('general')} {...a11yProps(0)} />
                    <Tab label={t('partlyCarBody')} {...a11yProps(1)} />
                    <Tab label={t('interior')} {...a11yProps(2)} />
                    {vehicle.isAld
                        ? <Tab label={t('ald')} {...a11yProps(3)} />
                        : null
                    }
                    <Tab label={t('equipment')} {...a11yProps(vehicle.isAld ? 4 : 3)} />
                </Tabs>
            </Grid>
            <Grid item xs={1} className={classes.cornerTab}>
                <UserMenu/>
            </Grid>
        </Grid>
    );
}

VehicleFormHeader.propTypes =  {
    handleChange: PropTypes.func.isRequired,
    tabNumber: PropTypes.number.isRequired,
}

export default VehicleFormHeader;
