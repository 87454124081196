import Switch from '@material-ui/core/Switch';
import {withStyles} from '@material-ui/core';

const SuccessSwitch = withStyles(theme => ({
    switchBase: {
        color: theme.palette.secondary.main,
        '&$checked': {
            color: theme.palette.success.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.success.secondary,
        },
    },
    checked: {},
    track: {},
}))(Switch);

export default SuccessSwitch;