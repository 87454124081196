import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import {connect} from 'react-redux';
import {setOpenModalId} from '../../store/reducer/modal';

const Modal = ({
    openModalId,
    modalId,
    size = false,
    title = '',
    closeIcon = true,
    transition = null,
    children,
    dispatch,
}) => {
    return (
        <Dialog
            maxWidth={size}
            fullWidth={size !== false}
            open={openModalId === modalId}
            onClose={() => {dispatch(setOpenModalId(''))}}
            // Following allow to conditionally add props
            { ...(transition ? {TransitionComponent: transition} : {})}
        >
            <DialogTitle style={{padding: '1rem 1rem 0 1rem'}}>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        {title}
                    </Grid>
                    {closeIcon
                        ? <Grid item>
                            <IconButton onClick={() => {
                                dispatch(setOpenModalId(''))
                            }}>
                                <CloseRoundedIcon/>
                            </IconButton>
                        </Grid>
                        : null
                    }
                </Grid>
            </DialogTitle>
            <DialogContent style={{padding: '0 1rem 1rem 1rem'}}>
                {children}
            </DialogContent>
        </Dialog>
    )
}

Modal.propTypes = {
    openModalId: PropTypes.string.isRequired,
    modalId: PropTypes.string.isRequired,
    size: PropTypes.string,
    title: PropTypes.string,
    closeIcon: PropTypes.bool,
    transition: PropTypes.object,
    children: PropTypes.object,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    openModalId: state.modalReducer.openModalId,
}))(Modal);