import PropTypes from 'prop-types';
import React from 'react';
import {setNewValueInVehicle} from '../../store/reducer/vehicle';
import {useDispatch} from 'react-redux';

const NotFullyGenericFileUploader = ({
    code,
    docNum,
    disabled = false,
    property,
    providedArray,
}) => {
    const dispatch = useDispatch();

    /**
     * Allow to get the file blob
     * The FileReader take times to read the File object
     * So we must create a Promise
     *
     * @params file (type : File)
     * @returns {Promise<string>}
     */
    const getFileBlob = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = (e) => {
                resolve(e.target.result);
            }

            reader.onerror = ((e) => {
                reject(e);
            })

            reader.readAsDataURL(file);
        })
    }

    /**
     * Put the photo(s) (as base64) in the corresponding item.code
     * It is async cause we need to wait the promise of the getFileBlob() function up above
     *
     * @param files (type : [ File ])
     * @returns {Promise<void>}
     */
    const handlePhoto = async (files) => {
        let carBodyPhotos = [];
        let clonedProvidedArray = [...providedArray];
        let key;

        // Get previous photo of the part
        clonedProvidedArray.map((item) => {
            if (code === item.partCode) {
                carBodyPhotos = [...item.files];
                key = item.files.length + 1;
            }
        })

        // Construct the photo object
        for (const file of Array.from(files)) {
            carBodyPhotos.push({
                fileName: docNum + '_' + code + '_' + key,
                file: await getFileBlob(file),
            })
            ++key;
        }

        // Put it into the providedArray
        clonedProvidedArray.map((item, key) => {
            if (code === item.partCode) {
                clonedProvidedArray[key] = {
                    ...item,
                    files: carBodyPhotos,
                }
            }
        })

        dispatch(setNewValueInVehicle({property: property, newValue: clonedProvidedArray}));
    }

    return (
        <input
            id={code}
            type="file"
            multiple={true}
            accept="image/*"
            onChange={e => {handlePhoto(e.target.files)}}
            disabled={disabled}
        />
    )
}

NotFullyGenericFileUploader.propTypes = {
    code: PropTypes.any.isRequired,
    docNum: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    property: PropTypes.string.isRequired,
    providedArray: PropTypes.array.isRequired,
}

export default NotFullyGenericFileUploader;