import Button from '@material-ui/core/Button';
import DangerButton from '../layout/variant/danger-style-component/DangerButton';
import DoneIcon from '@material-ui/icons/Done';
import Grid from '@material-ui/core/Grid';
import Modal from '../layout/Modal';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import SuccessButton from '../layout/variant/success-style-component/SuccessButton';
import moment from 'moment';
import {EMPTY_INPUT, setNewValueInFormError} from '../../store/reducer/formError';
import {HTTP_ERROR_TIMEOUT} from '../error/ErrorPage';
import {Zoom} from '@material-ui/core';
import {connect, useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'ramda';
import {makeStyles} from '@material-ui/core/styles';
import {postAld} from '../../api-service/AldApi';
import {postVehicle} from '../../api-service/VehicleApi';
import {reset as resetVehicleFolders} from '../../store/reducer/vehicleFolder';
import {setIsPersisting as setIsPersistingAld} from '../../store/reducer/ald';
import {setIsPersisting as setIsPersistingVehicle} from '../../store/reducer/vehicle';
import {setNewValueInVehicle} from '../../store/reducer/vehicle';
import {setOpenModalId} from '../../store/reducer/modal';
import {toBeValidatedVehicleGeneralFields} from '../../utils/fields-provider/VehicleGeneralFieldsProvider';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />;
});

const VehicleFormSave = ({
    vehicle,
    ald = null,
    formError,
    apiResultAld,
    apiResultAldPdf,
    apiResultVehicle,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [persist, setPersist] = useState(false);
    const [checkForm, setCheckForm] = useState(false);
    const [isToValidate, setIsToValidate] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const employee = useSelector(state => state.employeeReducer.employee);
    const {t} = useTranslation();

    const handleCheck = () => {
        toBeValidatedVehicleGeneralFields.forEach(field => {
            if (field.specificValidator) {
                if (field.specificValidator(vehicle[field.field])) {
                    dispatch(setNewValueInFormError(
                        {property: field.field, newValue: field.specificValidator(vehicle[field.field])}
                    ))
                }
            }

            if (field.required) {
                if (isEmpty(vehicle[field.field])) {
                    dispatch(setNewValueInFormError({property: field.field, newValue: t(EMPTY_INPUT)}))
                }
            }
        })

        setCheckForm(true);
    }

    useEffect(() => {
        if (checkForm) {
            if (isEmpty(formError)) {
                if (isToValidate) {
                    dispatch(setNewValueInVehicle({property: 'controlledAt', newValue: moment().format('YYYY-MM-DD')}));
                }

                dispatch(setNewValueInVehicle({property: 'controlledBy', newValue: employee.lastName + ' ' + employee.firstName}));
                setPersist(true);
            } else {
                enqueueSnackbar(
                    t('errorInFormFields'),
                    {variant: 'error'}
                );
            }
            setCheckForm(false);
        }
    }, [checkForm])

    useEffect(() => {
        if (persist) {
            postVehicle(vehicle).catch((error) => {
                if (error.message === HTTP_ERROR_TIMEOUT) {
                    enqueueSnackbar(
                        t('timeoutExceeded'),
                        {variant: 'error', autoHideDuration: 10000}
                    );
                } else {
                    enqueueSnackbar(
                        t('errorRequest'),
                        {variant: 'error', autoHideDuration: 10000}
                    );
                }

                dispatch(setIsPersistingVehicle(false));
            });
            if (ald) {
                postAld(ald).catch((error) => {
                    if (error.message === HTTP_ERROR_TIMEOUT) {
                        enqueueSnackbar(
                            t('timeoutExceeded'),
                            {variant: 'error', autoHideDuration: 10000}
                        );
                    } else {
                        enqueueSnackbar(
                            t('errorRequest'),
                            {variant: 'error', autoHideDuration: 10000}
                        );
                    }
                    dispatch(setIsPersistingAld(false));
                });
            }
            dispatch(resetVehicleFolders());
        }

        return () => {
            setPersist(false);
        }
    }, [persist]);

    useEffect(() => {
        if (!isEmpty(apiResultAld)) {
            enqueueSnackbar(
                apiResultAld.valid
                    ? t('ald') + ' : ' + t('successUpdate')
                    : t('ald') + ' : ' + t('errorUpdate'),
                {variant: apiResultAld.valid ? 'success' : 'error'}
            )
        }
    }, [apiResultAld]);

    useEffect(() => {
        if (!isEmpty(apiResultAldPdf)) {
            enqueueSnackbar(
                apiResultAldPdf.valid
                    ? t('ald') + ' : ' + t('successGeneratePdf')
                    : t('ald') + ' : ' + t('errorGeneratePdf'),
                {variant: apiResultAldPdf.valid ? 'success' : 'error'}
            )
        }
    }, [apiResultAldPdf])

    useEffect(() => {
        if (!isEmpty(apiResultVehicle)) {
            enqueueSnackbar(
                apiResultVehicle['valid']
                    ? t('vehicle') + ' : ' + t('successUpdate')
                    : t('vehicle') + ' : ' + t('errorUpdate'),
                {variant: apiResultVehicle.valid ? 'success' : 'error'}
            )
        }
    }, [apiResultVehicle]);

    return (
        <div className={classes.root}>
            <div
                style={{
                    position: 'fixed',
                    bottom: 10,
                    right: 32,
                }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<DoneIcon/>}
                    // tooltipTitle={t('validate')}
                    // tooltipOpen
                    onClick={() => {
                        dispatch(setOpenModalId('confirmValidate'));
                    }}
                    style={{
                        backgroundColor : 'royalblue',
                    }}
                >
                    {t('validate')}
                </Button>
            </div>

            <Modal modalId="confirmValidate" closeIcon={false} transition={Transition}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span>{t('confirmSentence')}</span>
                    </Grid>
                    <Grid container justifyContent="flex-end" item xs={12}>
                        <SuccessButton
                            variant="contained"
                            onClick={() => {
                                handleCheck();
                                setIsToValidate(true);
                                dispatch(setOpenModalId(''));
                                // handleClose();
                            }}
                        >
                            {t('yes')}
                        </SuccessButton>
                        <DangerButton
                            variant="contained"
                            style={{marginLeft: '.5rem'}}
                            onClick={() => {
                                dispatch(setOpenModalId(''));
                                // handleClose();
                            }}
                        >
                            {t('no')}
                        </DangerButton>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
}

VehicleFormSave.propTypes = {
    vehicle: PropTypes.object.isRequired,
    ald: PropTypes.object,
    formError: PropTypes.object.isRequired,
    connectionLost: PropTypes.bool,
    apiResultAld: PropTypes.object,
    apiResultAldPdf: PropTypes.object,
    apiResultVehicle: PropTypes.object,
}

export default connect(state => ({
    connectionLost: state.appReducer.connectionLost,
    apiResultAld: state.aldReducer.apiResult,
    apiResultAldPdf: state.aldReducer.apiResultPdf,
    apiResultVehicle: state.vehicleReducer.apiResult,
}))(VehicleFormSave);