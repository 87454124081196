import CustomCard from '../layout/CustomCard';
import DescriptionIcon from '@material-ui/icons/Description';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FormFieldRender from '../layout/FormFieldRender';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import VehicleFormMemo from './VehicleFormMemo';
import {
    firstSectionOfCarInformation,
    firstSectionOfGeneralInformation,
    fourthSectionOfGeneralInformation, secondSectionOfCarInformation,
    secondSectionOfGeneralInformation, thirdSectionOfCarInformation,
    thirdSectionOfGeneralInformation,
} from '../../utils/fields-provider/VehicleGeneralFieldsProvider';
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const VehicleFormGeneral = ({
    vehicle,
}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <form className={classes.root}>
            <Grid container spacing={2}>
                <Grid item container>
                    <CustomCard label={t('generalInfo')} icon={<DescriptionIcon/>}>
                        <Grid container>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {firstSectionOfGeneralInformation().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {secondSectionOfGeneralInformation().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {thirdSectionOfGeneralInformation().map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {fourthSectionOfGeneralInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item container>
                    <CustomCard label={t('carInfo')} icon={<DriveEtaIcon/>}>
                        <Grid container>
                            <Grid item xs={6} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {firstSectionOfCarInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            min={field.min}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            specificValidator={field.specificValidator}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    <Grid container item>
                                        <VehicleFormMemo vehicle={vehicle}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {secondSectionOfCarInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                    {thirdSectionOfCarInformation(vehicle).map((field, index) =>
                                        <FormFieldRender
                                            key={index}
                                            label={field.label}
                                            field={field.field}
                                            type={field.type}
                                            min={field.min}
                                            value={typeof field.value === 'undefined' ? vehicle[field.field] : field.value}
                                            required={field.required}
                                            specificValidator={field.specificValidator}
                                            split={field.split}
                                            inRow={field.inRow}
                                            disabled={field.disabled}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
        </form>
    );
}

VehicleFormGeneral.propTypes = {
    vehicle: PropTypes.object.isRequired,
}

export default VehicleFormGeneral;