import {apiPost} from './AbstractApi';
import {getStore} from '../index';
import {setEquipments, setHasError, setIsLoading} from '../store/reducer/equipment';

export const getEquipments = (data) => {
    getStore().dispatch(setIsLoading(true));
    return apiPost('/api/fleet-statement/equipments', data)
        .then(response => {
            getStore().dispatch(setEquipments(response));
        })
        .catch(error => {
            getStore().dispatch(setHasError(true));
            throw new Error(error.message);
        })
    ;
}
