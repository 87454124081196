import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import {animated, useSpring} from 'react-spring';
import {makeStyles} from '@material-ui/core';

const loadingStyles = makeStyles(() => ({
    image: {
        width: '100%',
    },
}));

const Loading = () => {
    const props = useSpring({opacity: 1, from: {opacity: 0}});
    const classes = loadingStyles();

    return (
        <>
            <LinearProgress/>
            <Grid container justifyContent="center">
                <Grid item xs={6}>
                    <animated.img style={props} className={classes.image} src="/logo.png"/>
                </Grid>
            </Grid>
        </>
    );
}

export default Loading;