import {clone} from 'ramda';
import {setNewValueInVehicle} from '../store/reducer/vehicle';

export const changeInteriorState = (state, code, simplifiedInteriors) => dispatch => {
    let found = false;
    let clonedSimplifiedInteriors = clone(simplifiedInteriors);

    if (simplifiedInteriors.length === 0) {
        clonedSimplifiedInteriors.push({
            partCode: code,
            partState: Number.parseInt(state),
            files: [],
        });
        found = true;
    } else {
        simplifiedInteriors.forEach((item, key) => {
            if (item.partCode === code) {
                found = true;
                clonedSimplifiedInteriors.splice(key,1);
                clonedSimplifiedInteriors.push({
                    partCode: code,
                    partState: Number.parseInt(state),
                    files: item.files,
                });
            }
        })
    }

    if (!found) {
        clonedSimplifiedInteriors.push({
            partCode: code,
            partState: Number.parseInt(state),
            files: [],
        });
    }

    dispatch(setNewValueInVehicle(
        {property: 'simplifiedInteriors', newValue: clonedSimplifiedInteriors}
    ));
}

export const getInteriorState = (code, simplifiedInteriors) => {
    let partState = 0;
    simplifiedInteriors.map((item) => {
        if (item.partCode === code) {
            partState = Number.parseInt(item.partState);
        }
    })

    return partState
}

export const getInteriorFiles = (code, simplifiedInteriors) => {
    let files = [];

    simplifiedInteriors.map((item) => {
        if (item.partCode === code) {
            files = item.files;
        }
    })

    return files
}