import i18n from 'i18next';
import {CHECKBOX_TYPE, RADIO_TYPE, TEXT_TYPE} from '../../components/layout/FormFieldRender';

export const windshieldAndLightData = (innerJoinPartlyCarBodyData) => {
    return [
        {
            label: i18n.t('windshield'),
            parts: [
                {
                    label: i18n.t('front'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '15'),
                },
                {
                    label: i18n.t('back'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '18'),
                },
            ],
        },
        {
            label: i18n.t('light') + ' ' + i18n.t('front'),
            parts: [
                {
                    label: i18n.t('front') + ' ' + i18n.t('right'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '07'),
                },
                {
                    label: i18n.t('front') + ' ' + i18n.t('left'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '27'),
                },
            ],
        },
        {
            label: i18n.t('light') + ' ' + i18n.t('front'),
            parts: [
                {
                    label: i18n.t('front') + ' ' + i18n.t('right'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '39'),
                },
                {
                    label: i18n.t('front') + ' ' + i18n.t('left'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '40'),
                },
            ],
        },
        {
            label: i18n.t('light') + ' ' + i18n.t('back'),
            parts: [
                {
                    label: i18n.t('front') + ' ' + i18n.t('right'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '11'),
                },
                {
                    label: i18n.t('front') + ' ' + i18n.t('left'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '24'),
                },
            ],
        },
    ]
}

export const tiresData = (innerJoinPartlyCarBodyData) => {
    return [
        {
            parts: [
                {
                    label: i18n.t('tire') + ' ' + i18n.t('front') + ' ' + i18n.t('right'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '37'),
                },
                {
                    label: i18n.t('tire') + ' ' + i18n.t('front') + ' ' + i18n.t('left'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '41'),
                },
            ],
        },
        {
            parts: [
                {
                    label: i18n.t('tire') + ' ' + i18n.t('back') + ' ' + i18n.t('right'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '04'),
                },
                {
                    label: i18n.t('tire') + ' ' + i18n.t('back') + ' ' + i18n.t('left'),
                    value: innerJoinPartlyCarBodyData.filter(carBodyPart => carBodyPart.code === '36'),
                },
            ],
        },
    ]
}

export const partlyCarBodyFields = () => {
    return [
        {
            label: i18n.t('frontBumper'),
            field: 'frontBumper',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('carHood'),
            field: 'carHood',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('leftFrontFender'),
            field: 'leftFrontFender',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('leftMirror'),
            field: 'leftMirror',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('leftFrontDoor'),
            field: 'leftFrontDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('leftBackDoor'),
            field: 'leftBackDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('leftSill'),
            field: 'leftSill',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('leftBackFender'),
            field: 'leftBackFender',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('backBumper'),
            field: 'backBumper',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('carTrunk'),
            field: 'carTrunk',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('utilityVehicle') + ' - ' + i18n.t('rightBackDoor'),
            field: 'utilityVehicleRightBackDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('utilityVehicle') + ' - ' + i18n.t('leftBackDoor'),
            field: 'utilityVehicleLeftBackDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('carRoof'),
            field: 'carRoof',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('rightBackFender'),
            field: 'rightBackFender',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('utilityVehicle') + ' - ' + i18n.t('slidingDoor'),
            field: 'utilityVehicleSlidingDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('rightBackDoor'),
            field: 'rightBackDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('leftBackDoor'),
            field: 'leftBackDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('rightFrontDoor'),
            field: 'rightFrontDoor',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('rightSill'),
            field: 'rightSill',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('rightMirror'),
            field: 'rightMirror',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
        {
            label: i18n.t('rightFrontFender'),
            field: 'rightFrontFender',
            type: RADIO_TYPE,
            choices: [
                {label: 'RSP', value: '0'},
                {label: 'P', value: '1'},
                {label: 'TP', value: '2'},
                {label: 'RP', value: '3'},
            ],
        },
    ];
};

export const interiorFields = () => {
    return [
        {
            label: i18n.t('rightFrontSeat'),
            field: 'rightFrontSeat',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'B'},
                {label: i18n.t('holey'), value: 'T'},
                {label: i18n.t('torn'), value: 'D'},
            ],
        },
        {
            label: i18n.t('leftFrontSeat'),
            field: 'leftFrontSeat',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'B'},
                {label: i18n.t('holey'), value: 'T'},
                {label: i18n.t('torn'), value: 'D'},
            ],
        },
        {
            label: i18n.t('backSeat'),
            field: 'backSeat',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'B'},
                {label: i18n.t('holey'), value: 'T'},
                {label: i18n.t('torn'), value: 'D'},
            ],
        },
        {
            label: i18n.t('frontCarpet'),
            field: 'frontCarpet',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'B'},
                {label: i18n.t('holey'), value: 'T'},
                {label: i18n.t('torn'), value: 'D'},
            ],
        },
        {
            label: i18n.t('backCarpet'),
            field: 'backCarpet',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'B'},
                {label: i18n.t('holey'), value: 'T'},
                {label: i18n.t('torn'), value: 'D'},
            ],
        },
        {
            label: i18n.t('dashboard'),
            field: 'dashboard',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'B'},
                {label: i18n.t('holey'), value: 'T'},
                {label: i18n.t('torn'), value: 'D'},
            ],
        },
        {
            label: i18n.t('misc'),
            field: 'miscInterior',
            type: TEXT_TYPE,
        },
    ];
}

export const mechanicFields = () => {
    return [
        {
            label: i18n.t('engine'),
            field: 'engine',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'Bon'},
                {label: i18n.t('fairCondition'), value: 'Moyen'},
                {label: i18n.t('bad'), value: 'Mauvais'},
                {label: i18n.t('shortenOutOfService'), value: 'H.S.'},
            ],
        },
        {
            label: i18n.t('clutch'),
            field: 'clutch',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'Bon'},
                {label: i18n.t('fairCondition'), value: 'Moyen'},
                {label: i18n.t('bad'), value: 'Mauvais'},
                {label: i18n.t('shortenOutOfService'), value: 'H.S.'},
            ],
        },
        {
            label: i18n.t('gearbox'),
            field: 'gearbox',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'Bon'},
                {label: i18n.t('fairCondition'), value: 'Moyen'},
                {label: i18n.t('bad'), value: 'Mauvais'},
                {label: i18n.t('shortenOutOfService'), value: 'H.S.'},
            ],
        },
    ];
}

export const generalStateFields = () => {
    return [
        {
            label: '',
            field: 'generalState',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('good'), value: 'Bon'},
                {label: i18n.t('fairCondition'), value: 'Moyen'},
                {label: i18n.t('bad'), value: 'Mauvais'},
            ],
        },
    ];
}

export const tiresFields = () => {
    return [
        {
            label: i18n.t('spareWheel'),
            field: 'spareWheel',
            type: RADIO_TYPE,
            choices: [
                {label: '90% - 60%', value: '90% à 60%'},
                {label: '60% - 30%', value: '60% à 30%'},
                {label: '< 30%', value: '< à 30%'},
            ],
        },
    ]
}

export const equipmentFields = () => {
    return [
        {
            label: i18n.t('radio'),
            field: 'hasRadio',
            type: CHECKBOX_TYPE,
        },
        {
            label: i18n.t('phone'),
            field: 'hasPhone',
            type: CHECKBOX_TYPE,
        },
        {
            label: i18n.t('gpsAndDisk'),
            field: 'hasGpsAndHisDisk',
            type: CHECKBOX_TYPE,
        },
        {
            label: i18n.t('misc'),
            field: 'miscEquipment',
            type: TEXT_TYPE,
        },
    ]
}

export const commentFields = () => {
    return [
        {
            label: i18n.t('comment'),
            field: 'comment',
            type: TEXT_TYPE,
        },
    ];
}