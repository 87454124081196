import {apiPost} from './AbstractApi';
import {getStore} from '../index';
import {setIsLoading, setVehicleFolders} from '../store/reducer/vehicleFolder';

export const getVehicleFolders = (data) => {
    getStore().dispatch(setIsLoading(true));
    return apiPost('/api/fleet-statement/vehicle-folders', data)
        .then(response => {
            getStore().dispatch(setVehicleFolders(response));
        })
        .catch(error => {
            throw new Error(error.message);
        })
    ;
}
