import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import Carousel from 'react-material-ui-carousel';
import CustomCard from '../layout/CustomCard';
import ErrorIcon from '@material-ui/icons/Error';
import FormControl from '@material-ui/core/FormControl';
import FormFieldRender from '../layout/FormFieldRender';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import Modal from '../layout/Modal';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NotFullyGenericFileUploader from '../layout/NotFullyGenericFileUploader';
import PropTypes from 'prop-types';
import React from 'react';
import RestrictedSlider from '../layout/RestrictedSlider';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {DriveEta} from '@material-ui/icons';
import {FormControlLabel, Radio, RadioGroup, makeStyles, useTheme} from '@material-ui/core';
import {capitalizeFirstLetter} from '../../utils/Misc';
import {changeInteriorState, getInteriorFiles, getInteriorState} from '../../utils/InteriorHandler';
import {
    generalFields,
    keyFields, overhaulFields,
    problemFields,
    warrantyFields,
} from '../../utils/fields-provider/VehicleInteriorFieldsProvider';
import {isEmpty} from 'ramda';
import {setNewValueInVehicle} from '../../store/reducer/vehicle';
import {setOpenModalId} from '../../store/reducer/modal';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

const fuelValues = ['<10%', '25%', '50%', '75%', '100%'];
const interiorRatingValues = ['Mauvais', 'Médiocre', 'Moyen -', 'Moyen +', 'Bon'];

const getFuelLevelValues = () => fuelValues.map((value, index) => {
    return {label: value, value: index * 25}
});

const getFuelLabelByValue = (value) => {
    return fuelValues.find(e => e.includes(value));
}

const getInteriorRatingValues = () => interiorRatingValues.map((value, index) => {
    return {label: value, value: index * 25}
});

const getInteriorValueByLabel = (label) => {
    let index = interiorRatingValues.findIndex(value => value.toLowerCase() === label.toLowerCase());
    return index * 25;
}

const getInteriorLabelByValue = (value) => {
    return interiorRatingValues[value / 25];
}

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: '100%',
        maxHeight: '70vh',
    },
}))

const VehicleFormInterior = ({
    vehicle,
    interiorData,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    function modalContent(files) {
        return (
            <Carousel
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                NextIcon={<NavigateNextIcon/>}
                PrevIcon={<NavigateBeforeIcon/>}
            >
                {files.map((file, key) => (
                    <img src={file.file} alt={file.fileName} key={key} className={classes.image}/>
                ))}
            </Carousel>
        )
    }

    const onChangeFuelLevel = (event, value) => {
        dispatch(setNewValueInVehicle({property: 'fuelLevel', newValue: getFuelLabelByValue(value)}))
    }

    const onChangeInteriorRating = (event, value) => {
        dispatch(setNewValueInVehicle({property: 'interiorRating', newValue: getInteriorLabelByValue(value).toLowerCase()}));
    }

    return (
        <form>
            <Grid container>
                <Grid xs={12} sm={6} item style={{display: 'flex'}}>
                    <CustomCard
                        label={t('fuelLevel')}
                        icon={<LocalGasStationIcon style={{marginRight: theme.spacing(2)}}/>}
                    >
                        <RestrictedSlider
                            values={getFuelLevelValues()}
                            defaultValue={!isEmpty(vehicle.fuelLevel)
                                ? parseInt(
                                    vehicle.fuelLevel.replace('<', '').replace('%', '')
                                )
                                : 0
                            }
                            onChange={onChangeFuelLevel}
                        />
                    </CustomCard>
                </Grid>
                <Grid xs={12} sm={6} item style={{display: 'flex'}}>
                    <CustomCard
                        label={t('interiorRating')}
                        icon={<StarHalfIcon style={{marginRight: theme.spacing(2)}}/>}
                    >
                        <RestrictedSlider
                            values={getInteriorRatingValues()}
                            defaultValue={parseInt(getInteriorValueByLabel(vehicle.interiorRating))}
                            onChange={onChangeInteriorRating}
                        />
                    </CustomCard>
                </Grid>
            </Grid>
            <Grid container style={{paddingTop: theme.spacing(2)}}>
                <Grid xs={12} sm={6} md={4} item style={{display: 'flex'}}>
                    <CustomCard
                        label={t('key')}
                        icon={<VpnKeyIcon style={{marginRight: theme.spacing(2)}}/>}
                    >
                        <Grid item container alignContent="flex-start">
                            <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                {keyFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid xs={12} sm={6} md={4} item style={{display: 'flex'}}>
                    <CustomCard
                        label={t('general')}
                        icon={<DriveEta style={{marginRight: theme.spacing(2)}}/>}
                    >
                        <Grid item container alignContent="flex-start">
                            <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                {generalFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                        split={field.split}
                                        disabled={field.disabled}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid xs={12} sm={6} md={4} item style={{display: 'flex'}}>
                    <CustomCard
                        label={t('issue')}
                        icon={<ErrorIcon style={{marginRight: theme.spacing(2)}}/>}
                    >
                        <Grid item container alignContent="flex-start">
                            <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                {problemFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                        split={field.split}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid item sm={6} md={4} container>
                    <CustomCard
                        label={t('warranty')}
                        icon={<ErrorIcon style={{marginRight: theme.spacing(2)}}/>}
                    >
                        <Grid item container alignContent="flex-start">
                            <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                {warrantyFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                        split={field.split}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
                <Grid xs={12} sm={6} md={4} item style={{display: 'flex'}}>
                    <CustomCard
                        label={t('overhaul')}
                        icon={<ErrorIcon style={{marginRight: theme.spacing(2)}}/>}
                    >
                        <Grid item container alignContent="flex-start">
                            <Grid item container style={{padding: '0 .2rem 0 .2rem'}}>
                                {overhaulFields().map((field, index) =>
                                    <FormFieldRender
                                        key={index}
                                        label={field.label}
                                        type={field.type}
                                        field={field.field}
                                        value={vehicle[field.field]}
                                        choices={field.choices ? field.choices : []}
                                        inRow={field.inRow}
                                        split={field.split}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={12} item>
                    <CustomCard
                        label={t('interior')}
                        icon={<AirlineSeatReclineExtraIcon/>}
                    >
                        <Grid container spacing={2}>
                            {interiorData.map((interiorDatum, index) =>
                                <Grid item key={index} xs={2}>
                                    <Grid container justifyContent="flex-start" style={{marginTop: '.5rem'}}>
                                        <Grid item>
                                            <Typography>
                                                <b>{capitalizeFirstLetter(interiorDatum.name)}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{marginLeft: '.5rem'}}>
                                            {getInteriorFiles(interiorDatum.code, vehicle.simplifiedInteriors).length >= 1
                                                ? <>
                                                    <IconButton
                                                        style={{padding: 0}}
                                                        onClick={() => {dispatch(setOpenModalId(interiorDatum.code))}}
                                                    >
                                                        <VisibilityIcon fontSize="medium"/>
                                                    </IconButton>
                                                    <Modal modalId={interiorDatum.code}>
                                                        {modalContent(getInteriorFiles(interiorDatum.code, vehicle.simplifiedInteriors))}
                                                    </Modal>
                                                </>
                                                : null
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="interior"
                                                    style={{marginBottom: '.5rem'}}
                                                    value={getInteriorState(interiorDatum.code, vehicle.simplifiedInteriors)}
                                                    onChange={e => {
                                                        dispatch(changeInteriorState(e.target.value, interiorDatum.code, vehicle.simplifiedInteriors))
                                                    }}
                                                >
                                                    <FormControlLabel value={1} control={<Radio />} label={interiorDatum.firstState}/>
                                                    <FormControlLabel value={2} control={<Radio />} label={interiorDatum.secondState}/>
                                                    <FormControlLabel value={3} control={<Radio />} label={interiorDatum.thirdState}/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item style={{display: 'flex', width: '12rem'}}>
                                            {getInteriorState(interiorDatum.code, vehicle.simplifiedInteriors) >= 1
                                                ? <NotFullyGenericFileUploader
                                                    code={interiorDatum.code}
                                                    docNum={vehicle.docNum}
                                                    property="simplifiedInteriors"
                                                    providedArray={vehicle.simplifiedInteriors}
                                                />
                                                : null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </CustomCard>
                </Grid>
            </Grid>
        </form>
    );
}

VehicleFormInterior.propTypes = {
    vehicle: PropTypes.object.isRequired,
    interiorData: PropTypes.array.isRequired,
}

export default VehicleFormInterior;