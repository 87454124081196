import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import SuccessButton from '../layout/variant/success-style-component/SuccessButton';
import {Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';

export const HTTP_ERROR_TIMEOUT = 'timeout';
export const HTTP_ERROR_500 = '500';
export const HTTP_ERROR_404 = '404';

const ErrorPage = () => {
    const {code} = useParams();
    let history = useHistory();
    const {t} = useTranslation();

    let errorMessage = code => {
        switch (code) {
        case HTTP_ERROR_500:
            return t('error500Message');
        case HTTP_ERROR_TIMEOUT:
            return t('errorTimeoutMessage');
        default:
            return t('error404Message');
        }
    }

    return (
        <>
            <AppBar position='fixed' style={{padding: '1.2rem'}}/>
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={12} container justifyContent="center">
                    <Typography variant="h1" style={{borderBottom: 'solid 1px', borderColor: 'red'}}>{t('error')} : {code}</Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <Typography variant="h5">{errorMessage(code)}</Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                    <SuccessButton onClick={() => {history.goBack()}}>{t('goBack')}</SuccessButton>
                </Grid>
            </Grid>
        </>
    );
}

export default ErrorPage;