import {apiGet, apiPost} from './AbstractApi';
import {getStore} from '../index';
import {setApiResult} from '../store/reducer/vehicle';
import {
    setIsLoading,
    setIsPersisting,
    setVehicle,
} from '../store/reducer/vehicle';
import {setIsOpenSnackBar} from '../store/reducer/snackbar';

export const getVehicle = (id) => {
    getStore().dispatch(setIsLoading(true));
    return apiGet('/api/fleet-statement/vehicle/' + id)
        .then(response => {
            getStore().dispatch(setVehicle(response));
        })
        .catch(error => {
            throw new Error(error.message);
        })
    ;
}

export const postVehicle = (data) => {
    getStore().dispatch(setIsPersisting(true));
    return apiPost('/api/fleet-statement/persist-vehicle', data)
        .then(response => {
            getStore().dispatch(setApiResult(response));
        })
        .then(() => {
            getStore().dispatch(setIsPersisting(false));
            getStore().dispatch(setIsOpenSnackBar(true));
            getStore().dispatch(setApiResult({}));
        })
        .catch(error => {
            throw new Error(error.message);
        })
    ;
}
