import Chip from '@material-ui/core/Chip';
import React from 'react'
import moment from 'moment/moment';
import preval from 'preval.macro'
import {Box} from '@material-ui/core';
import {Update} from '@material-ui/icons';
import 'moment/locale/fr';

const buildDate = preval`
module.exports = new Date()
`

const Version = () => {
    let tmpDate = new Date(buildDate)

    return (
        <Box ml={4} mt={2}>
            <Chip
                size="small"
                avatar={<Update />}
                label={'Mise à jour : ' + moment(tmpDate).locale('fr').format('LLLL')}
            />
        </Box>
    )
}

export default Version;