import {apiGet, apiPost} from './AbstractApi';
import {getStore} from '../index';
import {setAld, setApiResult, setApiResultPdf, setIsLoading, setNewValueInAld} from '../store/reducer/ald';
import {setIsOpenSnackBar} from '../store/reducer/snackbar';
import {setIsPersisting} from '../store/reducer/ald';

export const getAldData = (docEntryVehicle) => {
    getStore().dispatch(setIsLoading(true));
    return apiGet('/api/fleet-statement/ald/' + docEntryVehicle)
        .then(response => {
            if (response['valid']) {
                getStore().dispatch(setAld(response['ald']));
            } else {
                throw new Error(response['message'])
            }
        })
        .catch(error => {
            throw new Error(error.message);
        })
    ;
}

export const postAld = (data) => {
    getStore().dispatch(setIsPersisting(true));
    return apiPost('/api/fleet-statement/persist-ald', data)
        .then(response => {
            if (response['valid']) {
                // Hydrate Ald's code and name fields cause if isn't, the api will consider the current Ald object as new.
                getStore().dispatch(setNewValueInAld({property: 'code', newValue: response['code']}));
                getStore().dispatch(setNewValueInAld({property: 'name', newValue: response['code']}));
            }
            getStore().dispatch(setApiResult(response));
        })
        .then(() => {
            getStore().dispatch(setIsOpenSnackBar(true));
            getStore().dispatch(setIsPersisting(false));
            getStore().dispatch(setApiResult({}));
        })
        .catch(error => {
            throw new Error(error.message);
        })
    ;
}

export const generateAldPdf = (data, docEntry) => {
    getStore().dispatch(setIsPersisting(true));
    return apiPost('/api/fleet-statement/persist-ald', data)
        .then(response => {
            getStore().dispatch(setApiResult(response));
            return apiGet('/api/fleet-statement/generate-ald-pdf/' + docEntry)
        })
        .then(response => {
            getStore().dispatch(setIsPersisting(false));
            getStore().dispatch(setIsOpenSnackBar(true));
            getStore().dispatch(setApiResultPdf(response))
        })
        .then(() => {
            getStore().dispatch(setApiResult({}));
            getStore().dispatch(setApiResultPdf({}))
        })
    ;
}