import Interior from '../../model/Interior';
import PartlyCarBody from '../../model/PartlyCarBody';
import RepairCost from '../../model/RepairCost';
import {createSelector, createSlice} from '@reduxjs/toolkit';

let initialState =  {
    vehicleAnnexeData: {interior: [], partlyCarBody: [], repairCosts: []},
    isLoading: false,
    hasError: false,
    loaded: false,
    innerJoinPartlyCarBodyData: [],
    innerJoinPartlyCarBodyDataLoaded: false,
};

export const vehicleAnnexeDataSlice = createSlice({
    name: 'vehicleAnnexeData',
    initialState: initialState,
    reducers: {
        setVehicleAnnexeData: (state, action) => {
            return {
                ...state,
                vehicleAnnexeData: action.payload,
                loaded: true,
                isLoading: false,
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            }
        },
        setHasError: (state, action) => {
            return {
                ...state,
                hasError: action.payload,
            }
        },
        setInnerJoinPartlyCarBodyData: (state, action) => {
            return {
                ...state,
                innerJoinPartlyCarBodyData: action.payload,
            }
        },
        setInnerJoinPartlyCarBodyDataLoaded: (state, action) => {
            return {
                ...state,
                innerJoinPartlyCarBodyDataLoaded: action.payload,
            }
        },
        reset(state) {
            Object.assign(state, initialState)
        },
    },
});

export const interiorModel = createSelector(
    state => state.vehicleAnnexeDataReducer.vehicleAnnexeData,
    vehicleAnnexeData => vehicleAnnexeData.interior.map(interior => new Interior(interior))
);

export const partlyCarBodyModel = createSelector(
    state => state.vehicleAnnexeDataReducer.vehicleAnnexeData,
    vehicleAnnexeData => vehicleAnnexeData.partlyCarBody.map(partlyCarBody => new PartlyCarBody(partlyCarBody))
);

export const repairCostsModel = createSelector(
    state => state.vehicleAnnexeDataReducer.vehicleAnnexeData,
    vehicleAnnexeData => vehicleAnnexeData.repairCosts.map(repairCost => new RepairCost(repairCost))
);

export const {actions, reducer} = vehicleAnnexeDataSlice;

export const {
    setVehicleAnnexeData,
    setInnerJoinPartlyCarBodyData,
    setIsLoading,
    setHasError,
    setInnerJoinPartlyCarBodyDataLoaded,
    reset,
} = actions;
