import {apiGet} from './AbstractApi';
import {getStore} from '../index';
import {setHasError, setIsLoading, setVehicleAnnexeData} from '../store/reducer/vehicleAnnexeData';

export const getVehicleAnnexeData = () => {
    getStore().dispatch(setIsLoading(true));
    return apiGet('/api/fleet-statement/vehicle-annexe-data')
        .then(response => {
            getStore().dispatch(setVehicleAnnexeData(response));
        })
        .catch(error => {
            getStore().dispatch(setHasError(true));
            throw new Error(error.message);
        })
    ;
}
