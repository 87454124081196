import GradientBlueButton from '../layout/variant/gradient-style-component/GradiantBlueButton';
import Modal from '../layout/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import {TextField} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/styles';
import {setNewValueInVehicle} from '../../store/reducer/vehicle';
import {setOpenModalId} from '../../store/reducer/modal';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
    inputRoot: {
        '&$disabled': {
            backgroundColor: grey[300],
        },
    },
    disabled: {},
}))

const VehicleFormMemo = ({
    vehicle,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <GradientBlueButton
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                    dispatch(setOpenModalId('memo'))
                }}
            >
                {t('memo')}
            </GradientBlueButton>
            <Modal
                modalId="memo"
                size="lg"
                title={t('memo')}
            >
                <TextField
                    placeholder={t('emptyNote')}
                    defaultValue={vehicle.memo}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    multiline
                    fullWidth
                    onChange={(e) => {
                        dispatch(
                            setNewValueInVehicle({property: 'memo', newValue: e.target.value})
                        );
                    }}
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            disabled: classes.disabled,
                        },
                    }}
                />
            </Modal>
        </>
    )
}

VehicleFormMemo.propTypes = {
    vehicle: PropTypes.object.isRequired,
}

export default VehicleFormMemo;