import moment from 'moment';
import {BaseModel} from 'sjs-base-model';
import {isNil} from 'ramda';
import {typologyProvider} from '../utils/Misc';

export default class Vehicle extends BaseModel {
    docEntry = 0;
    docNum = 0;
    registrationNumber = '';
    transportStatus = '';
    vehicleType = '';
    brand = '';
    commercialType = '';
    serialNumber = '';
    mineType = '';
    carBody = '';
    energy = '';
    power = '';
    registeredAt = '';
    vintage = '';
    color = '';
    colorType = '';
    gearbox = '';
    memo = '';
    todo = '';
    damage = '';
    co2 = '';
    segment = '';
    vendor = '';
    transporter = '';
    orderNumber = '';
    complementOrderNumber = '';
    saleDate = '';
    parcName = '';
    sellerObservation = '';
    generalState = '';
    declaredMileage = '';
    kilometersAmountReal = '';
    internalRepairAmount = 0;
    doorsNumber = '';
    seatsNumber = '';
    missingSpareWheel = '';
    hasJack = '';
    hasTireRepairKit = '';
    isAssessed = false;
    isNotRolling = false;
    isDrivingSchool = false;
    isTaxi = false;
    isVsl = false;
    isAld = false;
    isWrecked = false;
    deliveredAt = '';
    controlledAt = '';
    controlledBy = '';
    typologie = '';
    typologieLabel = '';
    prixttcht = '';
    ptc = '';
    ptr = '';
    certcar = false;
    datemines = '';
    br = false;
    nbhd=0;
    nbh=0;
    nbhr=0;
    nonConformite = false;
    simplifiedCarBodyParts = [];
    simplifiedInteriors = [];
    simplifiedEquipments = [];
    carBodyPhotos = [];
    interiorPhotos = [];

    /** VehicleInterior **/
    userManual = '';
    interiorRating = '';
    fuelLevel = '';
    overhaul = '';
    kilometersOdometer = 0;
    maintenanceBook = '';
    maintenanceBookDeliveredAt = '';
    manufacturerWarranty = '';
    manufacturerWarrantyExpiresAt = '';
    hasKey = '';
    keyType = '';
    hasBatteryProblem = '';
    hasStartProblem = '';
    hasOilProblem = '';
    hasCoolingLiquidProblem = '';
    /** VehicleInterior **/

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
        this.controlledAt = moment(this.controlledAt.substring(0,10), true).isValid() ? moment(this.controlledAt.substring(0,10)).format('DD/MM/YYYY') : '';
        this.registeredAt = moment(this.registeredAt.substring(0,10), true).isValid() ? moment(this.registeredAt.substring(0,10)).format('DD/MM/YYYY') : '';
        this.deliveredAt = moment(this.deliveredAt.substring(0,10), true).isValid() ? moment(this.deliveredAt.substring(0,10)).format('YYYY-MM-DD') : '';
        this.datemines = moment(this.datemines.substring(0,10), true).isValid() ? moment(this.datemines.substring(0,10)).format('YYYY-MM-DD') : '';
        this.vendor = data['cardNameVendeur'] ?? '';
        this.transporter = data['transporteur'] ?? '';
        this.orderNumber = data['numeroOrdre'] ?? '';
        this.complementOrderNumber = data['numeroOrdreComplement'] ?? '';
        this.saleDate = data['dateVenteFR'] ?? '';
        this.parcName = data['nomParc'] ?? '';
        this.isAld = !isNil(data['statutReleveSpecial']) ? data['statutReleveSpecial'] !== 'NO' : false;
        this.typologieLabel = typologyProvider(data['typologie']);
    }
}