import orange from '@material-ui/core/colors/orange';
import {createTheme} from '@material-ui/core/styles';
import {dark} from '@material-ui/core/styles/createPalette';
import {red} from '@material-ui/core/colors';

const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                fontSize: '0.8rem',
            },
            sizeSmall: {
                padding: '0.5rem',
            },
        },
        MuiTableRow: {
            hover: {
                cursor: 'pointer',
            },
        },
    },
    palette: {
        background: {
            default: '#E3E3E3',
        },
        success: {
            main: '#28A745',
            secondary: '#1b692d',
        },
        primary: {
            main: '#19325f',
            dark: '#0E1D37',
        },
        warning: {
            main: orange[500],
        },
        danger: {
            main: red[500],
            almostMain: red[300],
            secondary: red[900],
            light: red[50],
        },
        dark: {
            main: dark[500],
        },
        secondary: {
            main: '#6C757D',
        },
        white: '#FFF',
        pink: {
            main: '#FF34C5',
        },
        blue: {
            main: '#005aff',
            secondary: '#0045cf',
            dark: '#003291',
            light: '#19b6ff',
        },
    },
});

export default theme;