const displayStyle = ({
    dFlex: {
        display: 'flex',
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    alignItemsCenter: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default displayStyle;