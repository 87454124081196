import i18n from 'i18next';
import {CHECKBOX_TYPE, NUMBER_TYPE, RADIO_TYPE, TEXT_TYPE} from '../../components/layout/FormFieldRender';

export const leftTallFields = () => {
    return [
        {
            label: i18n.t('todo'),
            field: 'todo',
            type: TEXT_TYPE,
        },
        {
            label: i18n.t('damage'),
            field: 'damage',
            type: TEXT_TYPE,
        },
    ];
}

export const rightSmallFields = () => {
    return [
        {
            label: i18n.t('sellerObservation'),
            field: 'sellerObservation',
            type: TEXT_TYPE,
            disabled: true,
        },
        {
            label: i18n.t('generalState'),
            field: 'generalState',
            type: NUMBER_TYPE,
            disabled: true,
        },
    ];
}

export const radioAndCheckboxFields = () => {
    return [
        {
            label: i18n.t('missingSpareWheel'),
            field: 'missingSpareWheel',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('yes'), value: 'Y'},
                {label: i18n.t('no'), value: 'N'},
            ],
            inRow: true,
        },
        {
            label: i18n.t('tireRepairKit'),
            field: 'hasTireRepairKit',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('yes'), value: 'Y'},
                {label: i18n.t('no'), value: 'N'},
                {label: i18n.t('incomplete'), value: 'incomplet'},
            ],
            inRow: true,
        },
        {
            label: i18n.t('jack'),
            field: 'hasJack',
            type: RADIO_TYPE,
            choices: [
                {label: i18n.t('yes'), value: 'Y'},
                {label: i18n.t('no'), value: 'N'},
            ],
            inRow: true,
        },
        {
            label: i18n.t('notRolling'),
            field: 'isNotRolling',
            type: CHECKBOX_TYPE,
            inRow: true,
        },
        {
            label: i18n.t('wrecked'),
            field: 'isWrecked',
            type: CHECKBOX_TYPE,
            inRow: true,
        },
    ];
}
