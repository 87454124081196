import {BaseModel} from 'sjs-base-model';

export default class RepairCost extends BaseModel {
    code = '';
    partCode = '';
    partState = '';
    segment = '';
    vehicleType = '';
    repairAmount = 0;

    constructor(data) {
        super();
        if (data) {
            this.update(data)
        }
    }

    update(data) {
        super.update(data);
    }

    /**
     * Use to push a serializable RepairCost object in the Redux store
     *
     * @returns {{code: string, repairAmount: number, segment: string, partCode: string, partState: string, vehicleType: string}}
     */
    toSerializableObject() {
        return (
            {
                code: this.code,
                partCode: this.partCode,
                partState: this.partState,
                segment: this.segment,
                vehicleType: this.vehicleType,
                repairAmount: this.repairAmount,
            }
        )
    }
}