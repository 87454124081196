const FIRST_RANGE = {
    codes: ['06', '26', '01', '22', '05', '25', '02', '23', '20'],
    min: 1,
    medium: 2,
    max: 4,
};

const SECOND_RANGE = {
    codes: ['39', '40', '15', '18'],
    min: 0.5,
    medium: 1,
    max: 1,
}

const THIRD_RANGE = {
    codes: ['11', '24', '07', '27'],
    min: 1,
    medium: 2,
    max: 2,
}

const FOURTH_RANGE = {
    codes: ['08', '30', '10', '33', '14', '19', '09', '34', '12', '13', '31', '32', '21'],
    min: 2,
    medium: 4,
    max: 8,
}

const FIFTH_RANGE = {
    codes: ['38', '42'],
    min: 2,
    medium: 2,
    max: 4,
}

const SIXTH_RANGE = {
    codes: ['16', '17', '28', '29'],
    min: 0,
    medium: 0,
    max: 4,
}

export const ratePartlyCarBody = (innerJoinPartlyCarBodyData) => {
    let score = 0;
    let generalRate = {comment: '', score: 0};

    [FIRST_RANGE, SECOND_RANGE, THIRD_RANGE, FOURTH_RANGE, FIFTH_RANGE, SIXTH_RANGE].forEach(range => {
        innerJoinPartlyCarBodyData.forEach(partlyCarBody => {
            if (range.codes.includes(partlyCarBody.partCode)) {
                if (partlyCarBody.partState === 1) {
                    score = Number(score + range.min);
                } else if (partlyCarBody.partState === 2) {
                    score = Number(score + range.medium);
                } else if (partlyCarBody.partState === 3) {
                    score = Number(score + range.max);
                }
            }
        })
    });
    
    if (score < 1) {
        generalRate.comment = 'très bon';
        generalRate.score = 10;
    } else if (score >= 1 && score <= 10) {
        generalRate.comment = 'bon';
        generalRate.score = 9;
    } else if (score > 10 && score <= 15) {
        generalRate.comment = 'bon-';
        generalRate.score = 8;
    } else if (score > 15 && score <= 25) {
        generalRate.comment = 'moyen';
        generalRate.score = 6;
    } else if (score > 25 && score <= 30) {
        generalRate.comment = 'moyen-';
        generalRate.score = 5;
    } else if (score > 30 && score <= 45) {
        generalRate.comment = 'médiocre';
        generalRate.score = 4;
    } else if (score > 45 && score <= 60) {
        generalRate.comment = 'mauvais';
        generalRate.score = 2;
    } else {
        generalRate.comment = 'très mauvais';
        generalRate.score = 0;
    }

    return generalRate;
}