import React from 'react';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {clone} from 'ramda';

export const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
    ;

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const iconProvider = (value) => {
    if (value) {
        let splitValues = value.split(' ');

        let formattedValues = splitValues.map(value => {
            if (value.includes('fa-', 0)) {
                return value.substring(3, value.length);
            } else if (value.includes('text-')) {
                let truncatedValue = value.substring(5, value.length);

                if (truncatedValue === 'success') {
                    truncatedValue = 'green';
                } else if (truncatedValue === 'danger') {
                    truncatedValue = 'red';
                } else if (truncatedValue === 'warning') {
                    truncatedValue = '#F5EA59';
                } else if (truncatedValue.indexOf('-')) {
                    truncatedValue = truncatedValue.substring(0, truncatedValue.indexOf('-'));
                }

                return truncatedValue;
            } else {
                return value;
            }
        });

        return (
            <>
                <FontAwesomeIcon icon={['fas', formattedValues[1]]} color={formattedValues[2]} size={formattedValues[3]} />
            </>
        )
    }
}

export const applyRepairCostDeprecationRule = (amount, registeredAt) => {
    let dateDiff = moment().diff(
        moment.utc(registeredAt, 'DD/MM/YYYY'),
        'years',
        true
    );

    if (dateDiff > 7) dateDiff = 7;

    let result = Math.round((amount * (1 - (dateDiff * 0.1))) * 100) / 100;

    return isNaN(result) ? 0 : result;
}

export const splitToChunks = (array, parts) => {
    let clonedArray = clone(array);
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(clonedArray.splice(0, Math.ceil(clonedArray.length / i)));
    }

    return result;
}

export const typologyProvider = (code) => {

    const typo = {
        1 : 'Véhicule',
        2 : 'Matériel immatriculé',
        3 : 'Matériel non immatriculé',
        4 : 'Divers',
    }
    return !typo[code] ? '' : typo[code]
}