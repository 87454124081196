import {createSlice} from '@reduxjs/toolkit';

export const snackBarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        isOpenSnackBar: false,
    },
    reducers: {
        setIsOpenSnackBar: (state, action) => {
            return {
                ...state,
                isOpenSnackBar: action.payload,
            }
        },
    },
});

export const {actions, reducer} = snackBarSlice;

export const {setIsOpenSnackBar} = actions;
