import {HTTP_ERROR_500, HTTP_ERROR_TIMEOUT} from '../components/error/ErrorPage';
import {getClientData} from '../utils/Authenticator';
import {getStore} from '../index';
import {setTokenLoaded} from '../store/reducer/authentication';

// TODO : make a constant with the '/api/fleet-statement/' string route

export const GET_METHOD = 'get';
export const DELETE_METHOD = 'delete';
export const POST_METHOD = 'post';

export const apiGet = (url, returnResponse = false) => {
    return apiFetchWithTimeout(GET_METHOD, url, false, returnResponse);
};

export const apiDelete = (url, returnResponse = false) => {
    return apiFetchWithTimeout(DELETE_METHOD, url, false, returnResponse);
};

export const apiPost = (url, data, returnResponse = false) => {
    return apiFetchWithTimeout(POST_METHOD, url, data, returnResponse);
};

// TODO : make timeout var a param
// TODO : enable recursive which mean a counter param that will exec this function as many as the counter param
const apiFetchWithTimeout = (method, url, data, returnResponse = false) => {
    let clientData = getClientData();
    let didTimeout = false;
    let controller = new AbortController();
    let signal = controller.signal;
    const FETCH_TIMEOUT = 300000;

    let init = {
        signal,
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + clientData.accessToken,
        },
    };

    if (data && typeof data !== 'undefined') {
        init.body = JSON.stringify(data);
    }

    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            didTimeout = true;
            reject(HTTP_ERROR_TIMEOUT);
            controller.abort();
        }, FETCH_TIMEOUT);

        fetch(process.env.REACT_APP_API_URL + url, init)
            .then(response => {
                clearTimeout(timeout);

                if (!didTimeout) {
                    if (response.status === 401) {
                        getStore().dispatch(setTokenLoaded(false));
                        resolve(null);
                    }

                    if (response.status <= 200 && response.status < 300) {
                        resolve(returnResponse ? response : response.json());
                    }

                    if (response.status === 500) {
                        reject(HTTP_ERROR_500);
                    }
                }
            }).catch((error) => {
                reject(HTTP_ERROR_500 + ' ' + error);
            })
        ;
    }).then(response => {
        return response;
    }).catch(error => {
        throw new Error(error);
    })
}