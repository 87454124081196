import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import RefreshButton, {STANDARD_BUTTON_TYPE} from './layout/RefreshButton';
import RefreshButtonOnClickAction, {ONCLICK_DISPATCH_TYPE} from '../model/RefreshButtonOnClickAction';
import TabPanel from './layout/TabPanel';
import Typography from '@material-ui/core/Typography';
import VehicleAld from './vehicle-form/VehicleAld';
import VehicleFormCarBody from './vehicle-form/VehicleFormCarBody';
import VehicleFormEquipment from './vehicle-form/VehicleFormEquipment';
import VehicleFormGeneral from './vehicle-form/VehicleFormGeneral';
import VehicleFormHeader from './vehicle-form/VehicleFormHeader';
import VehicleFormInterior from './vehicle-form/VehicleFormInterior';
import VehicleFormSave from './vehicle-form/VehicleFormSave';
import moment from 'moment/moment';
import {CircularProgress} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {aldModel} from '../store/reducer/ald';
import {ascend, clone, innerJoin, prop, sortWith} from 'ramda';
import {connect, useSelector} from 'react-redux';
import {getControlledLists} from '../api-service/ControlledListApi';
import {getVehicle} from '../api-service/VehicleApi';
import {getVehicleAnnexeData} from '../api-service/VehicleAnnexeApi';
import {
    interiorModel,
    partlyCarBodyModel,
    reset as resetVehicleAnnexeDataStore,
    setHasError as setHasErrorVehicleAnnexeData,
    setInnerJoinPartlyCarBodyData,
    setInnerJoinPartlyCarBodyDataLoaded,
    setIsLoading as setIsLoadingVehicleAnnexeData,
} from '../store/reducer/vehicleAnnexeData';
import {makeStyles} from '@material-ui/styles';
import {ratePartlyCarBody} from '../utils/PartlyCarBodyGeneralRating';
import {
    reset as resetControlledListStore,
    setHasError as setHasErrorControlledList,
    setIsLoading as setIsLoadingControlledList,
} from '../store/reducer/controlledList';
import {reset as resetVehicleStore, setNewValueInVehicle, vehicleModel} from '../store/reducer/vehicle';
import {router} from '../router';
import {setHeader} from '../store/reducer/app';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 10000,
    },
}));

const VehicleForm = ({
    isLoadingVehicle,
    isPersistingVehicle,
    isPersistingAld,
    hasErrorControlledList,
    hasErrorVehicleAnnexeData,
    vehicleLoaded,
    controlledListsLoaded,
    vehicleAnnexeLoaded,
    innerJoinPartlyCarBodyData,
    formError,
    dispatch,
}) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const {id} = useParams();
    const ald = useSelector(aldModel);
    const interiorData = useSelector(interiorModel);
    const partlyCarBodyData = useSelector(partlyCarBodyModel);
    const vehicle = useSelector(vehicleModel);
    const [tabNumber, setTabNumber] = useState(0);
    const {t} = useTranslation();

    const handleChange = (event, newValue) => {
        setTabNumber(newValue);
    };

    useEffect(() => {
        window.addEventListener('beforeunload', e => {
            // Cancel the event.
            // If you prevent default behavior in Mozilla Firefox prompt will always be shown.
            e.preventDefault();
            // Chrome requires returnValue to be set.
            e.returnValue = '';
        });
    })

    useEffect(() => {
        dispatch(setHeader(<VehicleFormHeader handleChange={handleChange} tabNumber={tabNumber}/>))

        return () => {
            dispatch(setHeader(null));
        }
    }, [tabNumber])

    useEffect(() => {
        getVehicle(id).catch(error => {
            history.replace(router.errorPage.formattedPath + error.message);
        });

        return () => {
            dispatch(resetVehicleStore());
        }
    }, [true]);

    useEffect(() => {
        if (vehicleLoaded && vehicle.deliveredAt === '') {
            dispatch(setNewValueInVehicle({property: 'deliveredAt', newValue: moment().format('YYYY-MM-DD')}));
        }
    }, [vehicleLoaded]);

    useEffect(() => {
        if (!vehicleAnnexeLoaded && !hasErrorVehicleAnnexeData) {
            const action =
                <RefreshButton
                    color="secondary"
                    type={STANDARD_BUTTON_TYPE}
                    onClickActions={[
                        new RefreshButtonOnClickAction(
                            {type: ONCLICK_DISPATCH_TYPE, action: resetVehicleAnnexeDataStore()}
                        ),
                    ]}
                />
            ;

            getVehicleAnnexeData().catch(() => {
                dispatch(setHasErrorVehicleAnnexeData(true));
                enqueueSnackbar(
                    <Typography style={{marginRight: '.5rem'}}>
                        {t('vehicleAnnexeDataNotLoadedError')}
                    </Typography>,
                    {variant: 'error', autoHideDuration: 10000, action}
                );
                dispatch(setIsLoadingVehicleAnnexeData(false));
            });
        }
    }, [vehicleAnnexeLoaded, hasErrorVehicleAnnexeData]);

    useEffect(() => {
        if (!controlledListsLoaded && !hasErrorControlledList) {
            const action =
                <RefreshButton
                    color="secondary"
                    type={STANDARD_BUTTON_TYPE}
                    onClickActions={[
                        new RefreshButtonOnClickAction(
                            {type: ONCLICK_DISPATCH_TYPE, action: resetControlledListStore()}
                        ),
                    ]}
                />
            ;

            getControlledLists().catch(() => {
                dispatch(setHasErrorControlledList(true));
                enqueueSnackbar(
                    <Typography style={{marginRight: '.5rem'}}>
                        {t('controlledListsNotLoadedError')}
                    </Typography>,
                    {variant: 'error', autoHideDuration: 10000, action}
                );
                dispatch(setIsLoadingControlledList(false));
            });
        }
    }, [controlledListsLoaded, hasErrorControlledList]);

    /**
     * The purpose of this useEffect is to embed innerJoinPartlyCarBodyData state.
     * It depend on the vehicle.simplifiedCarBodyParts data,
     * and if partlyCarBodyData is loaded (which is the info of each car body part).
     *
     * This useEffect also handle the innerJoinPartlyCarBodyData embed to each object the partState and files fields.
     */
    useEffect(() => {
        if (vehicleAnnexeLoaded && vehicleLoaded) {
            let generalRate = ratePartlyCarBody(vehicle.simplifiedCarBodyParts);

            // This, return for each damaged part, the associated partlyCarBodyData
            let clonedInnerJoinPartlyCarBodyData = innerJoin(
                (carBodyData, vehicleCarBodyPart) => vehicleCarBodyPart.partCode === carBodyData.code,
                partlyCarBodyData.map(carBodyData => carBodyData.toSerializableObject()),
                vehicle.simplifiedCarBodyParts
            );

            let clonedVehicle = clone(vehicle);

            clonedVehicle.simplifiedCarBodyParts.forEach(carBodyPart => {
                clonedInnerJoinPartlyCarBodyData.forEach((carBodyData, key) => {
                    if (carBodyData.code === carBodyPart.partCode) {
                        clonedInnerJoinPartlyCarBodyData[key] = {
                            ...carBodyData,
                            partState: carBodyPart.partState,
                            files: carBodyPart.files,
                        };
                    }
                })
            });

            let sortByName = sortWith([
                ascend(prop('name')),
            ]);

            dispatch(setNewValueInVehicle({property: 'generalState', newValue: generalRate.score.toString()}));
            dispatch(setNewValueInVehicle({property: 'sellerObservation', newValue: generalRate.comment + '/'}));

            dispatch(setInnerJoinPartlyCarBodyData(sortByName(clonedInnerJoinPartlyCarBodyData)));
            dispatch(setInnerJoinPartlyCarBodyDataLoaded(true));
        }
    }, [vehicle.simplifiedCarBodyParts, vehicleAnnexeLoaded]);

    return (
        <>
            {isLoadingVehicle
                ? <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} container style={{margin: '1.5rem 2rem 1rem 2rem'}}>
                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    height={230}
                                    style={{
                                        transform: 'none',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} container style={{margin: '0 2rem 0 2rem'}}>
                            <Grid item xs={12}>
                                <Skeleton
                                    animation="wave"
                                    height={355}
                                    style={{
                                        transform: 'none',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : <>
                    <Backdrop className={classes.backdrop} open={isPersistingVehicle || isPersistingAld}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <TabPanel value={tabNumber} index={0} id={id}>
                        <VehicleFormGeneral
                            vehicle={vehicle}
                        />
                    </TabPanel>
                    <TabPanel value={tabNumber} index={1}>
                        <VehicleFormCarBody
                            vehicle={vehicle}
                            innerJoinPartlyCarBodyData={innerJoinPartlyCarBodyData}
                        />
                    </TabPanel>
                    <TabPanel value={tabNumber} index={2}>
                        <VehicleFormInterior
                            vehicle={vehicle}
                            interiorData={interiorData}
                        />
                    </TabPanel>
                    {vehicle.isAld
                        ? <TabPanel value={tabNumber} index={3}>
                            <VehicleAld
                                vehicle={vehicle}
                                innerJoinPartlyCarBodyData={innerJoinPartlyCarBodyData}
                            />
                        </TabPanel>
                        : null
                    }
                    <TabPanel value={tabNumber} index={vehicle.isAld ? 4 : 3}>
                        <VehicleFormEquipment
                            vehicle={vehicle}
                        />
                    </TabPanel>
                    <VehicleFormSave
                        vehicle={vehicle}
                        ald={vehicle.isAld ? ald : null}
                        formError={formError}
                    />
                </>
            }
        </>
    );
}

VehicleForm.propTypes = {
    isLoadingVehicle: PropTypes.bool,
    isPersistingVehicle: PropTypes.bool,
    isPersistingAld: PropTypes.bool,
    hasErrorControlledList: PropTypes.bool,
    hasErrorVehicleAnnexeData: PropTypes.bool,
    vehicleLoaded: PropTypes.bool,
    controlledListsLoaded: PropTypes.bool,
    vehicleAnnexeLoaded: PropTypes.bool,
    innerJoinPartlyCarBodyData: PropTypes.array,
    formError: PropTypes.object,
    dispatch: PropTypes.func,
}

export default connect(state => ({
    isLoadingVehicle: state.vehicleReducer.isLoading,
    isPersistingVehicle: state.vehicleReducer.isPersisting,
    isPersistingAld: state.aldReducer.isPersisting,
    hasErrorControlledList: state.controlledListReducer.hasError,
    hasErrorVehicleAnnexeData: state.vehicleAnnexeDataReducer.hasError,
    vehicleLoaded: state.vehicleReducer.vehicleLoaded,
    controlledListsLoaded: state.controlledListReducer.loaded,
    vehicleAnnexeLoaded: state.vehicleAnnexeDataReducer.loaded,
    innerJoinPartlyCarBodyData: state.vehicleAnnexeDataReducer.innerJoinPartlyCarBodyData,
    formError: state.formErrorReducer.formError,
}))(VehicleForm);