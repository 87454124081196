import * as serviceWorker from './serviceWorker';
import App from './App';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import React from 'react';
import ReactDOM from 'react-dom';
import store from './store/store';
import theme from './assets/theme';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import {ThemeProvider} from '@material-ui/core/styles';
import {persistStore} from 'redux-persist';
import './fontAwesome';
import './i18n';

ReactDOM.render(
    <Provider store={store}>
        <React.Suspense fallback="loading">
            <PersistGate loading={null} persistor={persistStore(store)}>
                <Router>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <CssBaseline/>
                            <SnackbarProvider style={{pointerEvents: 'all'}}>
                                <App/>
                            </SnackbarProvider>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </Router>
            </PersistGate>
        </React.Suspense>
    </Provider>,
    document.getElementById('root')
);

export const getStore = () => store;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();